<template>
  <v-menu
    v-model="isShow"
    :attach="attach"
    ref="menu"
    :close-on-content-click="false"
    transition="scroll-y-transition"
    offset-y
    min-width="auto"
    dense
    :close-on-click="false"
  >
    <template v-slot:activator="{ attrs }">
      <v-row class="row-date">
        <v-col>
          <ValidationProvider
            v-slot="{ errors, valid }"
            :vid="id"
            :name="validation_label"
            :rules="validation_rules"
          >
            <v-text-field
              class="input-field"
              v-model="computedDateRange"
              placeholder="日付を選択"
              readonly
              :disabled="!editable"
              v-bind="attrs"
              filled
              dense
              item-text="name"
              :success="valid"
              :error-messages="errors"
              :data-testid="testid"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
    </template>
    <v-date-picker
      v-model="val"
      :key="val[0]"
      locale="ja"
      color="#00c3ac"
      :day-format="(val) => new Date(val).getDate()"
      :first-day-of-week="0"
      no-title
      scrollable
      dense
      @change="onInput(val)"
      range
    >
      <v-spacer></v-spacer>
      <v-btn text color="black" @click="resetDate(new Date())">クリア</v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { format, parseISO } from "date-fns";

export default {
  data: () => {
    return {
      val: [],
      isShow: true
    };
  },
  components: {
    ValidationProvider,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    isShowDateRange: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
    },
    name: {
      type: String,
    },
    validation_rules: {
      type: String,
    },
    validation_label: {
      type: String,
    },
    testid: {
      type: String,
    },
    id: {
      type: String,
    },
    pinIdSpecial: {
      type: String,
      default: ""
    },
  },
  computed: {
    attach() {
        return this.pinIdSpecial !== "" ? `#${this.pinIdSpecial}` : undefined 
    },
    computedDateRange() {
      if (this.val.length > 0) {
        const fromDate = this.val[0] ? this.val[0] : "";
        const toDate = this.val[1] ? this.val[1] : "";
        let formattedFromDate = "";
        let formattedToDate = "";
        if (fromDate && !fromDate.includes("/")) {
          formattedFromDate = format(parseISO(fromDate), "yyyy/MM/dd");
        } else {
          formattedFromDate = fromDate;
        }
        if (toDate && !toDate.includes("/")) {
          formattedToDate = format(parseISO(toDate), "yyyy/MM/dd");
        } else {
          formattedToDate = toDate;
        }
        return `${formattedFromDate} 〜 ${formattedToDate}`;
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        let fromDate = newValue[0].inspection_date_from;
        let toDate = newValue[0].inspection_date_to;
        fromDate = fromDate.includes("/")
          ? fromDate.replace(/\//g, "-")
          : fromDate;
        toDate = toDate.includes("/") ? toDate.replace(/\//g, "-") : toDate;
        if (fromDate && toDate) {
          const arrNew = [];
          arrNew.push(fromDate);
          arrNew.push(toDate);
          this.val = arrNew;
        }
      },
      { immediate: true, deep: true }
    );
  },
  methods: {
    onInput(val) {
      this.$refs.menu.save(val);
      const [fromVal, toVal] = val;
      this.fromVal = format(new Date(fromVal), "yyyy/MM/dd");
      this.toVal = format(new Date(toVal), "yyyy/MM/dd");
      this.$emit("onInput", {
        name: "customDate",
        value: { fromVal: this.fromVal, toVal: this.toVal },
      });
      this.isShowDateRange = false;
    },
    resetDate(current_date) {
      let currentDate = format(new Date(current_date), "yyyy-MM-dd");
      this.$refs.menu.save(currentDate);
      this.$emit("onInput", {
        name: "customDate",
        value: { fromVal: currentDate, toVal: currentDate },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.input-field {
  width: 210px !important;
}
.row-date {
  margin-top: -14px;
}
</style>
