<template>
  <div class="inpections">
    <DefaultLayout>
      <template #mainHeader>
        <SitePortalHeader />
      </template>
      <template #page="{ layoutParams }" class="temp">
        <TableLayout :layoutParams="layoutParams" :hideFooter="searchParams.pageCount >= searchParams.total_item">
          <template #tableHeader="{ updateHeader }">
            <div>
              <InspectionResultHeader ref="tableHeader" :pageTitle="PAGE_TITLE" :multiSelectStatus="disableApproveBtn"
                :updateHeader="updateHeader" :searchParams="searchParams" :disableBtnAll="disableBtnAll"
                :isRelateField="isRelateField" :isMachineFieldManager="isMachineFieldManager" @onReject="rejectConfirm"
                @onApprove="approveConfirm" @onApproveAll="approveAllConfirm" @onGuidance="guidenceAllConfirm"
                @onInput="onChangeSearchParams" @openPrint="openPrint">
                <SearchFormWrapper v-if="!isGuidanceMode">
                  <v-row class="row-position">
                    <v-col cols="2">
                      <Label label="機械名">
                        <InputText name="middle_classification" :editable="true" :values="searchInputs"
                          @onInput="onChangeSearchInputs" />
                      </Label>
                    </v-col>
                    <v-col cols="2" v-if="showSelectCompany">
                      <Label label="所有会社">
                        <Select name="company_id" :editable="true" :values="searchInputs" :items="COMPANY_ITEM"
                          @onInput="onChangeSearchInputs" />
                      </Label>
                    </v-col>
                    <v-col cols="2" v-if="showSelectCompany">
                      <Label label="点検会社">
                        <Select name="company_inspector" :editable="isContractorTabDate ? false : true"
                          :values="searchInputs" :items="COMPANY_INSPECTOR" @onInput="onChangeSearchInputs" />
                      </Label>
                    </v-col>
                    <v-col cols="2">
                      <Label label="管理名（呼名）">
                        <InputText name="machine_field_name" :editable="true" :values="searchInputs"
                          @onInput="onChangeSearchInputs" />
                      </Label>
                    </v-col>
                    <v-col cols="12" sm="1" md="1">
                      <SwitchInspection v-if="!isTabDate2" name="approved_status" label="未承認のみ表示" :values="searchInputs" :editable="true"
                        @onInput="onChangeSearchInputs" />
                      <SwitchInspection v-if="isTabDate2" name="leading_status" label="未指導のみ表示" :values="searchInputs" :editable="true"
                        @onInput="onChangeSearchInputs" />
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <SwitchInput name="deficiencies_confirm_status" label="不備・確認中のみ表示" :values="searchInputs"
                        :editable="true" @onInput="onChangeSearchInputs" />
                    </v-col>
                    <v-col cols="1">
                      <v-btn class="select-position" color="primary" depressed @click="onSearch(true)">
                        検索
                      </v-btn>
                    </v-col>
                  </v-row>
                </SearchFormWrapper>
                <SearchFormWrapper v-if="isGuidanceMode">
                  <v-row class="row-position">
                    <v-col cols="2">
                      <Label label="所有会社">
                        <Select name="company_id" :editable="!COMPANY_INSPECTOR_ID" item_text="company_name"
                          item_value="company_inspector" :values="searchLeadApprovedInputs" :items="COMPANY_BY_FIELDS"
                          @onInput="onChangeSearchLeadApprovedInputs" />
                      </Label>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <SwitchInspection class="switch-position" name="lead_approved_flag" label="未指導"
                        :values="searchLeadApprovedInputs" :editable="true"
                        @onInput="onChangeSearchLeadApprovedInputs" />
                    </v-col>
                    <v-col cols="1">
                      <v-btn class="select-position" color="primary" depressed @click="onSearch(false)">
                        検索
                      </v-btn>
                    </v-col>
                  </v-row>
                </SearchFormWrapper>
              </InspectionResultHeader>
              <TableTab :items="INSPECTION_RESULT_TABLE_TAB_ITEMS" name="inspection_frequency" :values="searchParams"
                @onInput="onChangeTableTab">
                <TableSort :values="searchParams" 
                          :page_counts_options="PAGE_COUNT_OPTIONS"
                          :total_item="searchParams.total_item"
                          :pageCount="searchParams.pageCount"
                          @onInput="onChangeSortParams" 
                />
              </TableTab>
            </div>
          </template>
          <template #tableBody="{ tableHeight }">
            <v-data-table v-if="!isGuidanceMode" v-model="selectedItems" :headers="TABLE_LABELS" :items="items"
              :items-per-page="searchParams.pageCount" :height="tableHeight" fixed-header hide-default-footer
              disable-sort class="elevation-0 v-data-table__wrapper" sort-by="updatedAt"
              :show-select="true" noDataText="データがありません。" item-key="key"
              @click:row="openItemForm">
              <template v-slot:[`item.unapproved_count`]="{ item }">
                <div>
                  {{ `${item.unapproved_count}(${item.inspection_count})` }}
                </div>
              </template>
              <template v-slot:[`item.flag_error`]="{ item }" >
                <div v-if="!isMonthlyMode">
                <div v-if="item.flag_error == 1" style="color: #B00020">
                  不備
                </div>
                <div v-if="item.flag_error == 2">
                  <span style="color: #B00020">
                    不備
                  </span>
                  &nbsp;
                  <span style="color: #FF7B52">
                    是正済み
                  </span>
                </div>
                <div v-if="item.flag_error == 3">
                  <span style="color: #B00020">
                    不備
                  </span>
                  &nbsp;
                  <span style="color: #1B9C4F">
                    確認済み
                  </span>
                </div>
                <div v-if="item.flag_error == 4">
                  <span style="color: #B00020">
                    不備
                  </span>
                  &nbsp;
                  <span style="color: #FF7B52">
                    是正済み
                  </span>
                  &nbsp;
                  <span style="color: #1B9C4F">
                    確認済み
                  </span>
                </div>
                </div>
                <div  v-if="isMonthlyMode"></div>
              </template>
            </v-data-table>
            <v-data-table v-if="isGuidanceMode" v-model="selectedLeadApproved" :headers="TABLE_LABELS"
              :items="LIST_LEAD_APPROVED" :items-per-page="searchParams.pageCount" :height="tableHeight" fixed-header
              hide-default-footer disable-sort class="elevation-0 v-data-table__wrapper" sort-by="updatedAt" show-select
              noDataText="データがありません。" item-key="company_id">
              <template v-slot:[`item.guidance_status`]="{ item }">
                <div>
                  {{ item.lead_approved_month ? "指導済" : "ー" }}
                </div>
              </template>
              <template v-slot:[`item.lead_approved_at`]="{ item }">
                <div>
                  {{ item.lead_approved_at ? item.lead_approved_at : "ー" }}
                </div>
              </template>
              <template v-slot:[`item.preview_guild_dance`]="{ item }">
                <v-btn
                  class="float-right"
                  color="primary"
                  depressed
                  item-key="{item.key}"
                  @click="openDialogTemplate('prevGuildDance', item.company_id)"
                >
                  プレビュー
                </v-btn>
              </template>
            </v-data-table>
          </template>
          <template #tableFooter>
            <Pagination :current="searchParams.currentPage" :total="searchParams.totalPage" @pageUpdate="pageUpdate" />
          </template>
        </TableLayout>

        <div class="loading" v-if="isLoading">
          <div class="loader"></div>
        </div>

      </template>
    </DefaultLayout>
    <Popup :dialog="popups.isShowInspecForm1" width="1600px">
      <InspectionForm1 :isDialogComfirm="isDialogComfirm" :item="editedItem" :isReportDay="isReportDay" :isRelateField="isRelateField"
        :isMachineFieldManager="isMachineFieldManager" :inspection_frequency="apiParams.inspection_frequency" :company_type="this.USER_LOGIN.company_type"
        @cancel="closeItemForm" @getItems="getItems" />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowDialog">
      <ConfirmDialog @close="closeDialogApproval" @yes="actionDialog" :title="popups.titleDialog"
        :message="popups.messageDialog" :warning="popups.nameAction == 'reject_approve' ? true : false" />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowPrintDialog">
      <ConfirmPrintDialog @close="closePrintDialog" @yes="print" />
    </Popup>
    <Popup width="640px" :dialog="popups.isShowNoticeDialog">
      <NotificationDialog @close="closeNoticeDialog" :date="popups.maintainDate" :time="popups.maintainTime" :message="popups.messageDialog" />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowUpdateErrorDialog">
      <UpdateStatusErrorDialog @close="closeDialogUpdateError" />
    </Popup>
    <Popup width="302mm" :dialog="popups.isShowDialogTemplateGuildDance" class="dialog-template">
        <v-card>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn small icon text @click="closeDialogTemplate()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <TemplateGuildDance
              :listInspectionApprovalStatus = "listInspectionApprovalStatus"
            ></TemplateGuildDance>
        </v-card>
    </Popup>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSort from "@/views/fields/inspections/components/TableSort";
import Popup from "@/components/common/Popup";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import ConfirmDialog from "@/views/fields/inspections/components/ConfirmDialog.vue";
import { TABLES_PER_PAGE } from "@/constants/COMMON";

import InputText from "@/components/forms/elements/InputText";
import Select from "@/components/forms/elements/Select";
import Label from "@/components/forms/elements/Label";
import SwitchInspection from "@/views/fields/inspections/components/SwitchInspection";
import InspectionResultHeader from "@/views/fields/inspections/components/InspectionResultHeader.vue";
import ConfirmPrintDialog from "@/views/fields/inspections/components/ConfirmPrintDialog.vue";
import NotificationDialog from "@/views/fields/inspections/components/NotificationDialog.vue";
import TableTab from "@/views/fields/inspections/components/TableTab.vue";
import InspectionForm1 from "@/components/forms/inspections/InspectionForm1.vue";
import SwitchInput from "@/components/forms/elements/SwitchInput";
import {
  INSPECTIONS_TABLE_LABELS,
  ATTRIBUTE_DIALOG_ARRRAY,
  INSPECTION_RESULT_CATEGORY,
  INSPECTION_GUIDANCE_LABELS,
  CONTRACTOR_INSPECTIONS_TABLE_LABELS_DATE2,
} from "@/constants/INSPECTION";
import { COMPANY_TYPE } from "@/constants/COMMON";
import { HEADER_MENU_ITEMS_FIELD } from "@/constants/GLOBALHEADER";
import { format } from "date-fns";
import _ from "lodash";
import axios from "axios";
import UpdateStatusErrorDialog from "@/views/fields/inspections/components/UpdateStatusErrorDialog.vue";
import TemplateGuildDance from "./templates/TemplateGuildDance.vue";

let DEFAULT_INSPECTION_RESULT_TABLE_TAB_ITEMS = {
  TAB_DATE: { id: 0, name: "作業前点検" },
  TAB_MONTH: { id: 1, name: "月例点検" },
  TAB_GUIDANCE: { id: 2, name: "点検指導実績一覧" },
};

let CONTRACTOR_INSPECTION_RESULT_TABLE_TAB_ITEMS = {
  TAB_DATE: { id: 0, name: "作業前点検(自社)" },
  TAB_DATE2: { id: 3, name: "作業前点検(他社)" },
  TAB_MONTH: { id: 1, name: "月例点検" },
  TAB_GUIDANCE: { id: 2, name: "点検指導実績一覧" },
};

let INSPECTION_RESULT_TABLE_TAB_ITEMS = DEFAULT_INSPECTION_RESULT_TABLE_TAB_ITEMS;

const PAGE_TITLE = "点検実績";

const PAGE_COUNT = 25;

const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

const STORE = "Inspections";

let TABLE_LABELS = INSPECTIONS_TABLE_LABELS;

let INITIAL_ITEM_SEARCH_PARAMS = {
  inspection_date_from: format(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    "yyyy/MM/dd"
  ),
  inspection_date_to: format(new Date(), "yyyy/MM/dd"),
  lead_approved_month: format(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    "yyyy/MM/dd"
  ),
  inspection_frequency: INSPECTION_RESULT_TABLE_TAB_ITEMS.TAB_DATE.id,
  company_id: null,
  company_inspector: null,
  pageCount: PAGE_COUNT,
  currentPage: 1,
  totalPage: 1,
};

const INITIAL_ITEM_SEARCH_INPUTS = {
  approved_status: null,
  middle_classification: null,
  company_id: null,
  company_inspector: null,
  deficiencies_confirm_status: 0,
  leading_status:null
};

const INITIAL_ITEM_LEAD_APPROVED = {
  company_id: null,
  lead_approved_flag: null,
};

export default {
  head: {
    title() {
      return { inner: "", separator: " ", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      PAGE_TITLE,
      TABLE_LABELS,
      PAGE_COUNT_OPTIONS,
      ATTRIBUTE_DIALOG_ARRRAY,
      INSPECTION_GUIDANCE_LABELS,
      INSPECTIONS_TABLE_LABELS,
      CONTRACTOR_INSPECTIONS_TABLE_LABELS_DATE2,
      items: [],
      selectedItems: [],
      selectedLeadApproved: [],
      isNewItem: false,
      editedItem: {},
      COMPANY_ITEM: [],
      COMPANY_INSPECTOR: [],
      COMPANY_BY_FIELDS: [],
      LIST_LEAD_APPROVED: [],
      isRelateField: null,
      isMachineFieldManager: null,
      isDialogComfirm : true,
      isLoading: false,
      // search param
      searchParams: { ...INITIAL_ITEM_SEARCH_PARAMS },
      NOTIFICATION: "",

      popups: {
        isShowInspecForm1: false,
        isShowPrintDialog: false,
        isShowNoticeDialog: false,
        isShowDialogTemplateGuildDance: false,
        isShowDialog: false,
        titleDialog: "",
        messageDialog: "",
        nameAction: "",
        isShowUpdateErrorDialog: false,
        maintainDate: "",
        maintainTime: "",
      },
      listInspectionApprovalStatus: [],
      searchInputs: { ...INITIAL_ITEM_SEARCH_INPUTS },
      searchLeadApprovedInputs: { ...INITIAL_ITEM_LEAD_APPROVED },
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },

  components: {
    DefaultLayout,
    TableLayout,
    Pagination,
    SearchFormWrapper,
    TableSort,
    SitePortalHeader,
    Popup,
    InputText,
    Select,
    Label,
    SwitchInspection,
    InspectionResultHeader,
    TableTab,
    ConfirmDialog,
    InspectionForm1,
    ConfirmPrintDialog,
    NotificationDialog,
    UpdateStatusErrorDialog,
    SwitchInput,
    TemplateGuildDance
},

  async mounted() {
    //Prevent BrowserのBack、Forward
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
    

    this.getListCompany();
    this.getListCompanyByField();
    this.getNotification();
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_FIELD.INSPECTOR_FIELD.id,
    });

    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
        Store.getters[`${STORE}/getFlagRelateField`],
        Store.getters[`${STORE}/getFlagMachineFieldManager`],
        Store.getters[`${STORE}/getInspectionFrequency`],
      ],
      (data) => {
        data[0].forEach((element) => {
          element.key = element.ids.toString();
        });
        this.items = data[0];
        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        // searchParams.inspection_frequency = data[4];
        this.searchParams = searchParams;
        this.isRelateField = data[2];
        this.isMachineFieldManager = data[3];
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => [
        Store.getters[`FieldUser/getDataLeadApproved`],
        Store.getters[`FieldUser/getPaginationLeadApproved`],
      ],
      (data) => {
        this.LIST_LEAD_APPROVED = data[0];
        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      () => {
        this.onChangeTableTab(this.searchParams.inspection_frequency);
        // this.resetSearchParams();
      },
      {
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Sites/getListCompanyByField"],
      (data) => {
        this.COMPANY_INSPECTOR = [...data];
        this.COMPANY_INSPECTOR.unshift({
          id: null,
          name: "",
        });
        if (this.USER_LOGIN.company_type === 0 && this.searchParams?.inspection_frequency === 3) {
          for( var i = 0; i < this.COMPANY_INSPECTOR.length; i++){ 
            if ( this.COMPANY_INSPECTOR[i].id === this.USER_LOGIN.company_id) {
              this.COMPANY_INSPECTOR.splice(i, 1);
              break;
            }
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["Sites/getCompanyByFields"],
      (data) => {
        if (
          this.USER_LOGIN.company_type != COMPANY_TYPE.OWNER ||
          this.USER_LOGIN.company_type != COMPANY_TYPE.ADMIN
        ) {
          this.searchLeadApprovedInputs["company_id"] =
            this.USER_LOGIN.company_id;
        }
        this.COMPANY_BY_FIELDS = [...data];
        this.COMPANY_BY_FIELDS.unshift({
          company_inspector: null,
          company_name: "",
        });
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.getListCompanyByField();
          this.getCompanyByFields();
        }
      },
      {
        deep: true,
      }
    );
    this.getItems(0);
  },

  computed: {
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },

    disableApproveBtn() {
      return (
        this.selectedItems.length === 0 &&
        this.selectedLeadApproved.length === 0
      );
    },
    disableBtnAll() {
      return this.items.length === 0;
    },
    COMPANY_INSPECTOR_ID() {
      return this.USER_LOGIN.company_type != COMPANY_TYPE.OWNER &&
        this.USER_LOGIN.company_type != COMPANY_TYPE.ADMIN
        ? this.USER_LOGIN.company_id
        : null;
    },

    apiParams() {
      return {
        field_id: this.CURRENT_SITE.field_id,
        inspection_date_from: this.searchParams.inspection_date_from,
        inspection_date_to: this.searchParams.inspection_date_to,
        company_id: this.searchParams.company_id,
        company_inspector: this.searchParams.company_inspector,
        machine_field_name: this.searchParams.machine_field_name,
        middle_classification: this.searchParams.middle_classification,
        inspection_frequency: this.searchParams.inspection_frequency,
        approved_status: this.searchParams.approved_status,
        deficiencies_confirm_status:
          this.searchParams.deficiencies_confirm_status,
        page_number: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
        leading_status: this.searchParams.leading_status,
      };
    },
    apiGetListLeadApprovedParams() {
      return {
        field_id: this.CURRENT_SITE.field_id,
        lead_approved_month: this.searchParams.lead_approved_month,
        company_id: this.COMPANY_INSPECTOR_ID
          ? this.COMPANY_INSPECTOR_ID
          : this.searchParams.company_id,
        lead_approved_flag:
          this.searchParams.lead_approved_flag == 0 ? 1 : null,
        page_number: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
      };
    },

    INSPECTION_RESULT_TABLE_TAB_ITEMS() {
      this.checkCompanyType();
      return Object.keys(INSPECTION_RESULT_TABLE_TAB_ITEMS).map((key) => {
        return INSPECTION_RESULT_TABLE_TAB_ITEMS[key];
      });
    },

    USER_LOGIN() {
      return Store.getters[`Login/getUser`];
    },

    isContractorTabDate() {
      if (this.USER_LOGIN.company_type === 0 && this.searchParams?.inspection_frequency === 0) {
        return true;
      } else {
        return false;
      }
    },
    isGuidanceMode() {
      return this.searchParams?.inspection_frequency ==
        INSPECTION_RESULT_TABLE_TAB_ITEMS.TAB_GUIDANCE.id
        ? true
        : false;
    },
    isMonthlyMode() {
      return this.searchParams?.inspection_frequency ==
        INSPECTION_RESULT_TABLE_TAB_ITEMS.TAB_MONTH.id
        ? true
        : false;
    },

    /**
     * Show select search if role is owner or admin
     */
    showSelectCompany() {
      if (
        this.USER_LOGIN?.company_type === COMPANY_TYPE.PATNER ||
        this.USER_LOGIN?.company_type === COMPANY_TYPE.RENTAL
      )
        return false;
      return true;
    },

    isReportDay() {
      return this.searchParams?.inspection_frequency === 0;
    },
    isTabDate2() {
      return this.searchParams?.inspection_frequency === 3;
    },
  },

  methods: {
    formUpdate(params) {
      this.editedItem = { ...params };
    },
    checkCompanyType() {
      if (this.USER_LOGIN.company_type === 0) {
        INSPECTION_RESULT_TABLE_TAB_ITEMS =
          CONTRACTOR_INSPECTION_RESULT_TABLE_TAB_ITEMS;
        if (this.searchParams?.inspection_frequency === 1) {
          this.TABLE_LABELS = this.INSPECTIONS_TABLE_LABELS;
        }
      } else {
        INSPECTION_RESULT_TABLE_TAB_ITEMS = DEFAULT_INSPECTION_RESULT_TABLE_TAB_ITEMS
        this.TABLE_LABELS = INSPECTIONS_TABLE_LABELS;
      }
    },
    /**
     * (Common)
     * pageUpdate
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      if (
        this.searchParams.inspection_frequency ==
        INSPECTION_RESULT_TABLE_TAB_ITEMS.TAB_GUIDANCE.id
      ) {
        this.getListLeadApproved();
      } else if (this.searchParams.inspection_frequency === 3) {
        this.getOtherItems();
      }else {
        this.getItems();
      }
    },

    /**
     * (Common)
     * onSearch
     */
    onSearch(flag) {
      this.selectedItems = [];
      this.selectedLeadApproved = [];
      if (flag) {
        this.searchParams = { ...this.searchParams, ...this.searchInputs };
        this.searchParams["currentPage"] = 1;
        if (this.searchParams["inspection_frequency"] === 3) {
          this.getOtherItems();
        } else {
          this.getItems();
        }
      } else {
        this.searchParams = {
          ...this.searchParams,
          ...this.searchLeadApprovedInputs,
        };
        this.searchParams["currentPage"] = 1;
        this.getListLeadApproved();
      }
    },

    /**
     * (Common)
     * onChangeSearchInputs
     * @param {name:String,value:String}
     */
    onChangeSearchInputs({ name, value }) {
      let searchInputs = { ...this.searchInputs };
      searchInputs[name] = value;
      this.searchInputs = searchInputs;
    },
    /**
     * (Common)
     * onChangeSearchInputs
     * @param {name:String,value:String}
     */
    onChangeSearchLeadApprovedInputs({ name, value }) {
      let searchLeadApprovedInputs = { ...this.searchLeadApprovedInputs };
      searchLeadApprovedInputs[name] = value;
      this.searchLeadApprovedInputs = searchLeadApprovedInputs;
    },

    onChangeSearchParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      if (name == "customDate") {
        searchParams.inspection_date_from = value.fromVal;
        searchParams.inspection_date_to = value.toVal;
      } else {
        searchParams[name] = format(new Date(value), "yyyy/MM/dd");
      }
      this.searchParams = searchParams;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
      if (
        this.searchParams.inspection_frequency ==
        INSPECTION_RESULT_TABLE_TAB_ITEMS.TAB_GUIDANCE.id
      ) {
        this.getListLeadApproved();
      } else if (this.searchParams.inspection_frequency === 3) {
        this.getOtherItems(name);
      } else {
        this.getItems(name);
      }
    },

    /**
     * (Common))
     * onChangeSortParams
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      if (
        this.searchParams.inspection_frequency ==
        INSPECTION_RESULT_TABLE_TAB_ITEMS.TAB_GUIDANCE.id
      ) {
        this.getListLeadApproved();
      } else if (this.searchParams.inspection_frequency === 3) {
        this.getOtherItems();
      }else {
        this.getItems();
      }
    },
    // resetSearchParams
    resetSearchParams() {
      this.searchParams = { ...INITIAL_ITEM_SEARCH_PARAMS };
      this.searchInputs = { ...INITIAL_ITEM_SEARCH_INPUTS };
      this.searchLeadApprovedInputs = { ...INITIAL_ITEM_LEAD_APPROVED };
      this.selectedLeadApproved = [];
      this.selectedItems = [];
    },

    async openItemForm(item) {
      let ids = item.ids;
      const result = await Store.dispatch(`${STORE}/getByIds`, { ids });
      if (!result.hasError) {
        this.editedItem = _.cloneDeep(result.data.contents.entries);
        this.popups.isShowInspecForm1 = true;
      } else {
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
    },

    closeItemForm() {
      this.popups.isShowInspecForm1 = false;
      this.$nextTick(() => {
        this.editedItem = {};
        this.selectedItems = [];
      });
    },

    closeDialogApproval() {
      this.popups.isShowDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },

    actionDialog() {
      let action = this.popups.nameAction;
      if (action === INSPECTION_RESULT_CATEGORY.REJECT_APPROVE) {
        this.changeApproveItems(0);
      } else if (action === INSPECTION_RESULT_CATEGORY.APPROVE_ITEMS) {
        this.changeApproveItems(1);
      } else if (action === INSPECTION_RESULT_CATEGORY.APPROVE_ALL_ITEMS) {
        this.approveAll();
      } else if (action === INSPECTION_RESULT_CATEGORY.GUIDANCE_ALL) {
        this.guidenceAll();
      }
    },

    rejectConfirm() {
      this.popups.isShowDialog = true;
      this.popups.messageDialog =
        ATTRIBUTE_DIALOG_ARRRAY.CANCEL_APPROVAL.message;
      this.popups.titleDialog = ATTRIBUTE_DIALOG_ARRRAY.CANCEL_APPROVAL.name;
      this.popups.nameAction = INSPECTION_RESULT_CATEGORY.REJECT_APPROVE;
    },

    approveConfirm() {
      this.popups.isShowDialog = true;
      this.popups.messageDialog = ATTRIBUTE_DIALOG_ARRRAY.APPROVAL.message;
      this.popups.titleDialog = ATTRIBUTE_DIALOG_ARRRAY.APPROVAL.name;
      this.popups.nameAction = INSPECTION_RESULT_CATEGORY.APPROVE_ITEMS;
    },

    approveAllConfirm() {
      if (this.items.length == 0) return;
      this.popups.isShowDialog = true;
      this.popups.messageDialog = ATTRIBUTE_DIALOG_ARRRAY.APPROVAL_ALL.message;
      this.popups.titleDialog = ATTRIBUTE_DIALOG_ARRRAY.APPROVAL_ALL.name;
      this.popups.nameAction = INSPECTION_RESULT_CATEGORY.APPROVE_ALL_ITEMS;
    },

    guidenceAllConfirm() {
      if (this.items.length == 0) return;
      this.popups.isShowDialog = true;
      this.popups.messageDialog = ATTRIBUTE_DIALOG_ARRRAY.GUIDANCE_ALL.message;
      this.popups.titleDialog = ATTRIBUTE_DIALOG_ARRRAY.GUIDANCE_ALL.name;
      this.popups.nameAction = INSPECTION_RESULT_CATEGORY.GUIDANCE_ALL;
    },

    async getItems(dataGet) {
      this.isLoading = typeof dataGet == "string" && dataGet == "customDate" ? false : true;
      if (this.CURRENT_SITE.field_id) {
        let apiParams = {
          ...this.apiParams,
          inspection_frequency: dataGet && typeof dataGet == "number"
            ? dataGet
            : this.apiParams.inspection_frequency,
        };
        if (
          this.USER_LOGIN?.company_type === COMPANY_TYPE.PATNER ||
          this.USER_LOGIN?.company_type === COMPANY_TYPE.RENTAL
        ) {
          this.TABLE_LABELS = INSPECTIONS_TABLE_LABELS;
          delete apiParams?.company_id;
          delete apiParams?.company_inspector;
        }
        if(this.apiParams.inspection_frequency===1){
          let inspection_date_to = new Date(apiParams.inspection_date_to);
          apiParams.inspection_date_to = format(new Date(inspection_date_to.getFullYear(),
            inspection_date_to.getMonth() + 1, 0), 'yyyy/MM/dd');
        }
        await Store.dispatch(`${STORE}/get`, apiParams).then(() => {
          this.isLoading = false;
        });
      }
    },

    async getOtherItems(dataGet) {
      this.isLoading = dataGet == "customDate" ? false : true;
      if (this.CURRENT_SITE.field_id) {
        let apiParams = { ...this.apiParams };
        await Store.dispatch(`${STORE}/getListOtherCompany`, apiParams).then(() => {
          this.isLoading = false;
        });
      }
    },

    /**
     * change approve items
     */
    async changeApproveItems(approved_status) {
      const ids = [];
      this.selectedItems.forEach((e) => {
        e.ids.forEach((id) => {
          ids.push(id);
        });
      });
      if (ids == null || ids.length == 0) return;
      let params = {
        ids,
        approved_status,
        inspection_frequency: this.apiParams.inspection_frequency,
      };

      const result = await Store.dispatch(
        `${STORE}/updateStatusByGroup`,
        params
      );

      if (!result.hasError) {
        if (result.data.contents.entries.update_status) {
          // update success
          this.closeDialogApproval();
          this.getItems();
          Store.dispatch("Toast/show", {
            status: 200,
            message: "登録しました",
          });
        } else {
          this.closeDialogApproval();
          this.popups.isShowUpdateErrorDialog = true;
        }
      } else {
        this.closeDialogApproval();
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
    },

    /**
     * approve All
     */
    async approveAll() {
      let apiParams = { ...this.apiParams };
      if (apiParams.inspection_frequency === 0) {
        apiParams.company_inspector = this.USER_LOGIN.company_id;
      }
      const result = await Store.dispatch(`${STORE}/approvalSeries`, apiParams);
      if (!result.hasError) {
        if (result.data.contents.entries.update_status) {
          // close dialog
          this.closeDialogApproval();
          this.getItems();
          Store.dispatch("Toast/show", {
            status: 200,
            message: "登録しました",
          });
        } else {
          this.closeDialogApproval();
          this.popups.isShowUpdateErrorDialog = true;
        }
      } else {
        this.closeDialogApproval();
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
    },

    async guidenceAll() {
      const apiParams = {
        field_id: this.CURRENT_SITE.field_id,
        inspection_date_from: this.searchParams.inspection_date_from,
        inspection_date_to: this.searchParams.inspection_date_to,
      };
      const result = await Store.dispatch(
        `${STORE}/addLeadApproved`,
        apiParams
      );
      if (!result.hasError) {
        // close dialog
        this.closeDialogApproval();
        if (this.searchParams?.inspection_frequency === 3) {
          this.getOtherItems();
        } else {
          this.getItems();
        }
        Store.dispatch("Toast/show", {
          status: 200,
          message: "登録しました",
        });
      } else {
        this.closeDialogApproval();
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
    },

    onChangeTableTab(id) {
      this.isDialogComfirm = true;
      this.resetSearchParams();
      const searchParams = { ...this.searchParams };
      searchParams.inspection_frequency = id;
      this.checkCompanyType();
      this.searchParams = searchParams;
      if (searchParams.inspection_frequency === 0) {
        this.TABLE_LABELS = INSPECTIONS_TABLE_LABELS;
        this.getItems();
      } else if (
        searchParams.inspection_frequency ==
        INSPECTION_RESULT_TABLE_TAB_ITEMS.TAB_GUIDANCE.id
      ) {
        this.TABLE_LABELS = this.INSPECTION_GUIDANCE_LABELS;
        this.getListLeadApproved();
        this.getCompanyByFields();
      } else if (searchParams.inspection_frequency === 3) {
        this.TABLE_LABELS = this.CONTRACTOR_INSPECTIONS_TABLE_LABELS_DATE2;
        this.isDialogComfirm = false;
        this.getOtherItems();
      } else {
        this.TABLE_LABELS = this.INSPECTIONS_TABLE_LABELS;
        this.getItems();
      }
      this.getListCompanyByField();
    },

    openPrint() {
      this.popups.isShowPrintDialog = true;
    },

    closePrintDialog() {
      this.popups.isShowPrintDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
        this.selectedLeadApproved = [];
      });
    },

    closeNoticeDialog(status) {
      const STORAGE_KEY = "LOGGED_IN";
      if (status) {
        let notifyId = JSON.parse(localStorage.getItem(STORAGE_KEY) || '[]');
        notifyId.push(this.NOTIFICATION);
        localStorage.setItem(STORAGE_KEY, JSON.stringify(notifyId));
      }
      this.popups.isShowNoticeDialog = false;
    },

    // print machine
    async print(type) {
      let ids = [];
      let params = {};
      let URL_EXPORT_FILE = `${process.env.VUE_APP_API_BASE_URL}/inspections/get_machine_prework_checklist`;
      if (
        this.searchParams.inspection_frequency ==
        INSPECTION_RESULT_TABLE_TAB_ITEMS.TAB_GUIDANCE.id
      ) {
        ids = this.selectedLeadApproved.map((items) => items.company_id);
        params = {
          file_code: type,
          field_id: this.CURRENT_SITE.field_id,
          inspection_month: this.searchParams.lead_approved_month,
          company_ids: ids,
        };
        URL_EXPORT_FILE = `${process.env.VUE_APP_API_BASE_URL}/reports/inspection_approval_status_list`;
      }
      else if (this.searchParams.inspection_frequency == 3) {
        ids = this.selectedItems.map((items) => items.acceptant_number);
        params = {
          file_code: type,
          acceptant_number: ids,
          inspection_date_from: this.searchParams.inspection_date_from,
          inspection_date_to: this.searchParams.inspection_date_to,
          leading_status: this.searchParams.leading_status,
          approved_status: this.searchParams.approved_status,
          inspection_frequency: 0,
        };
      } else {
        ids = this.selectedItems.map((items) => items.acceptant_number);
        params = {
          file_code: type,
          acceptant_number: ids,
          inspection_date_from: this.searchParams.inspection_date_from,
          inspection_date_to: this.searchParams.inspection_date_to,
          approved_status: this.searchParams.approved_status,
          inspection_frequency: this.searchParams.inspection_frequency,
        };
      }
      axios({
        url: URL_EXPORT_FILE,
        method: "GET",
        params: params,
        timeout: 8000,
        interceptors: {
          request: axios.interceptors.request.use((config) => {
            const GRN = JSON.parse(sessionStorage.getItem("GRN"));
            sessionStorage.getItem("GRN") &&
              (config.headers.Authorization = `${GRN.Login.api_token}`);
            return config;
          }),
        },
      })
        .then((response) => {
          if (response.data.status_code == 200) {
            this.closePrintDialog();
            const { file, file_name } = response.data.contents;
            const byteCharacters = atob(file);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const url = window.URL.createObjectURL(new Blob([byteArray]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", file_name);
            document.body.appendChild(link);
            link.click();
          } else {
            this.closePrintDialog();
            const error_status = response.data.status_code;
            let error_message = "";
            switch (true) {
              case /401/.test(error_status):
                error_message = "認証エラー";
                break;
              case /503/.test(error_status):
                error_message = "メンテナンス中です";
                break;
              default:
                error_message = "システムエラー";
            }
            Store.dispatch(
              "Error/show",
              {
                status: response.data.status,
                message: error_message,
              },
              { root: true }
            );
          }
        })
        .catch(() => {
          this.closePrintDialog();
          Store.dispatch(
            "Error/show",
            {
              status: 500,
              message: "システムエラー",
            },
            { root: true }
          );
        });
    },

    /**
     * get data select 所有会社
     */
    async getListCompany() {
      const response = await Store.dispatch(`Company/get`);
      if (!response.hasError) {
        const { entries } = response.data.contents;
        this.COMPANY_ITEM = [...entries];
        this.COMPANY_ITEM.unshift({
          id: null,
          name: "",
        });
      }
    },
    async getListCompanyByField() {
      if (this.CURRENT_SITE.field_id) {
        let apiParams = {
          field_id: this.CURRENT_SITE.field_id,
        };
        await Store.dispatch(`Sites/getCompanyByField`, apiParams);
      }
    },
    async getNotification() {
      const STORAGE_KEY = "LOGGED_IN";
      if (this.prevRoute.path=='/') {
        const response = await Store.dispatch(`CmnMst/getNotification`);
        if (!response.hasError) {
          const { entries } = response.data.contents;
          if(entries !== null) {
            this.NOTIFICATION = entries.id;
            let notifyId = JSON.parse(localStorage.getItem(STORAGE_KEY) || '[]');
            if (!notifyId.includes(this.NOTIFICATION)) {
              this.popups.isShowNoticeDialog = true;
              this.popups.maintainDate = entries.date;
              this.popups.maintainTime = entries.time;
              this.popups.messageDialog = entries.content;
            }
          }
        }
      }
    },
    closeDialogUpdateError() {
      this.popups.isShowUpdateErrorDialog = false;
    },
    async getListLeadApproved() {
      this.isLoading = true;
      await Store.dispatch(
        `FieldUser/getListLeadApproved`,
        this.apiGetListLeadApprovedParams
      ).then(() => {
        this.isLoading = false;
      });
    },
    async getCompanyByFields() {
      if (this.CURRENT_SITE.field_id) {
        await Store.dispatch(
          `Sites/getListCompanyByField`,
          this.CURRENT_SITE.field_id
        );
      }
    },
    openDialogTemplate(template, companyIds){
      if (template == 'prevGuildDance'){
        this.getInspectionApprovalStatus(companyIds)
      }
    },
    async getInspectionApprovalStatus(companyIds){
      let params = {
        inspection_month: this.searchParams.lead_approved_month,
        company_ids: [companyIds],
        field_id: this.CURRENT_SITE.field_id,
      }
      await Store.dispatch("Inspections/getInspectionApprovalStatus", { params }).then((response)=> {
        if(response.data) {
          this.listInspectionApprovalStatus = response.data.contents.entries;
          this.popups.isShowDialogTemplateGuildDance = true;
        }
      }).catch((error) => {
        console.log(error);
      });
    },
    closeDialogTemplate(){
      this.popups.isShowDialogTemplateGuildDance = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.row-position {
  height: 108px;
  position: relative;
}

.select-position {
  position: absolute;
  right: 29px;
  top: 30px;
}

.switch-position {
  position: absolute;
  left: 318px;
  top: 31px;
}

::v-deep .v-data-table__wrapper table tbody tr td:nth-child(5) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 17vw;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loading{
  top: 0px;
  position: absolute;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  z-index: 9999;
  display: flex;
}
.loader{
  margin: 0 auto;
}
.temp{
  position: relative;
}
.inpections ::v-deep .tableHeader {
  position: relative;
  z-index: 8;
  .inspection-result {
      position: relative;
      z-index: 1;
      .v-sheet {
        position: relative;
        z-index: 1;
      }
  }
}
</style>
