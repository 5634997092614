<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <InhousePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout :layoutParams="layoutParams" :hideFooter="searchParams.pageCount >= searchParams.total_item">
          <template #tableHeader="{ updateHeader }">
            <TableHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :multiRemoveStatus="disableRemoveBtn"
              :updateHeader="updateHeader"
              :addButtonTitle="ADD_BUTTON_TITLE"
              :disableBtnAdd="disableBtnAdd"
              @openRemoveDialog="openRemoveDialog"
              @openItemForm="openNewItemForm"
            >
              <SearchFormWrapper>
                <Label label="会社名">
                  <InputText
                    name="word"
                    :editable="true"
                    :values="searchInputs"
                    @onInput="onChangeSearchInputs"
                  />
                </Label>
                <Label label="">
                  <TabSelect
                    name="company_type"
                    :items="COMPANY_CONTRACT"
                    :editable="true"
                    :values="searchInputs"
                    @onInput="onChangeSearchInputs"
                  />
                </Label>
                <v-spacer></v-spacer>
                <v-btn class="mx-2" color="primary" depressed @click="onSearch">
                  検索
                </v-btn>
              </SearchFormWrapper>
            </TableHeader>
            <TableSortWrapper>
              <TableSort
                :values="searchParams"
                :sort_items="SORT_ITEMS"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams"
              />
            </TableSortWrapper>
          </template>
          <template #tableBody="{ tableHeight }">
            <v-data-table
              v-model="selectedItems"
              :headers="TABLE_LABELS"
              :items="items"
              :items-per-page="searchParams.pageCount"
              :height="tableHeight"
              fixed-header
              hide-default-footer
              disable-sort
              class="elevation-0 v-data-table__wrapper"
              sort-by="updatedAt"
              show-select
              noDataText="データがありません。"
              item-key="id"
              @click:row="openItemForm"
            >
              <template v-slot:[`item.company_type`]="{ item }">
                {{ handleCompanyType(item.company_type) }}
              </template>
              <template v-slot:[`item.address`]="{ item }">
                {{ handleAddress(item) }}
              </template>
            </v-data-table>
          </template>
          <template #tableFooter>
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <Popup :dialog="popups.isShowItemForm">
      <CompanyForm
        :isNewItem="isNewItem"
        @cancel="closeItemForm"
        ref="CompanyForm"
      />
    </Popup>

    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :items="selectedItems"
        warning
      />
    </Popup>
  </div>
</template>
<script>
/**
 * (Common)
 * Table common components and functions
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import TableLayout from "@/components/layout/TableLayout.vue";
import TableHeader from "@/components/masterTable/elements/TableHeader.vue";
import Pagination from "@/components/masterTable/elements/Pagination.vue";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper.vue";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper.vue";
import TableSort from "@/components/masterTable/elements/TableSort.vue";
import Popup from "@/components/common/Popup.vue";
import ConfirmRemoveDialog from "./components/ConfirmRemoveDialog.vue";
import InhousePortalHeader from "@/components/globalHeader/InhousePortalHeader";
import { TABLES_PER_PAGE, TABLE_SORT_ORDERS } from "@/constants/COMMON";

/**
 * Components and functions used by company
 */
import InputText from "@/components/forms/elements/InputText.vue"; 
import Label from "@/components/forms/elements/Label.vue";
import TabSelect from "@/components/forms/elements/TabSelect";
import CompanyForm from "@/components/forms/company/index";
import {
  COMPANY_TABLE_LABELS,
  COMPANY_SORT_ITEMS,
  COMPANY_CONTRACT,
  COMPANY_TYPE_ARR,
} from "@/constants/COMPANY";
import {
  COMPANY_TYPE,
} from "@/constants/COMMON";
import { HEADER_MENU_ITEMS_INHOUSE } from "@/constants/GLOBALHEADER";

// page_title
const PAGE_TITLE = "会社";

// Number of tables per page
const PAGE_COUNT = 25;

// Number of tables per page option
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// sort orders
const SORT_ORDERS = TABLE_SORT_ORDERS;

// STORE
const STORE = "Company";

// Table header label
const TABLE_LABELS = COMPANY_TABLE_LABELS;

// Sort element
const SORT_ITEMS = COMPANY_SORT_ITEMS;

// Button add title
const ADD_BUTTON_TITLE = "新規作成";

export default {
  head: {
    title() {
      return { inner: "", separator: " ", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      PAGE_TITLE,
      TABLE_LABELS,
      SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      ADD_BUTTON_TITLE,
      COMPANY_TYPE,
      items: [],
      selectedItems: [],
      isNewItem: false,
      // searchParams
      searchParams: {
        word: "",
        created_at: null,
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: null,
        asc: true,
        company_type: []
      },
      popups: {
        isShowItemForm: false,
        isShowRemoveDialog: false,
      },
      searchInputs: {
        word: "",
        company_type: []
      }
    };
  },

  components: {
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    InhousePortalHeader,
    Popup,
    ConfirmRemoveDialog,
    InputText,
    Label,
    TabSelect,
    CompanyForm
  },

  async mounted() {
    //Prevent BrowserのBack、Forward
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };

    Store.dispatch("GlobalHeader/setInHouseMenu", 
      {menuId: HEADER_MENU_ITEMS_INHOUSE.COMPANY.id});
    Store.dispatch("GlobalHeader/setSite", {
        field_id: null,
      });
    /**
     * (Common)
     * get data table
     */
    this.getItems();

    /**
     * (Common)
     * Get data and pagination
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        /**
         *  (Common)
         * set data list table
         */
        this.items = data[0];

        /**
         *  (Common)
         * Pagination update
         */
        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immidiate: true,
        deep: true,
      }
    );
  },

  computed: {
    /**
     * (Common)
     * Delete button active / inactive
     * selectedItems.length === 0
     */
    disableRemoveBtn() {
      return this.selectedItems.length === 0;
    },
  
    COMPANY_CONTRACT() {
      return Object.keys(COMPANY_CONTRACT).map((key) => {
        return COMPANY_CONTRACT[key];
      });
    },

    apiParams() {
       return {
          name: this.searchParams.word,
          company_type: this.searchParams.company_type,
          sort_by: this.searchParams.asc ? 1 : 0,
          page_number: this.searchParams.currentPage,
          page_size: this.searchParams.pageCount,
          sort_value:  this.searchParams.sort,
       };
    },

    /**
     * Info company_type of user login
     */
    companyType() {
      return Store.getters[`Login/getUser`]?.company_type;
    },

    /**
     * set condition disabled button add
     */
    disableBtnAdd() {
      return this.companyType === this.COMPANY_TYPE.PATNER || this.companyType === this.COMPANY_TYPE.RENTAL;
    },
  },

  methods: {
    /**
     * (Common)
     * Pagination event
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (Common)
     * onSearch
     */
    onSearch() {
      this.searchParams = {...this.searchParams, ...this.searchInputs};
      this.searchParams['currentPage'] = 1;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
      this.getItems();
    },

    /**
     * (Common)
     * onChangeSearchInputs
     * @param {name:String,value:String}
     */
    onChangeSearchInputs({ name, value }) {
      let searchInputs = { ...this.searchInputs };
      searchInputs[name] = value;
      this.searchInputs = searchInputs;
    },

    /**
     * (Common)
     * onChangeSortParams
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (Common)
     * Open new/detail/edit form
     */
    openNewItemForm() {
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },
    async openItemForm(item) {
      const result = await Store.dispatch(`${STORE}/getCompanyDetail`, item.id);
      if(!result.hasError) {
        this.isNewItem = false;
        this.popups.isShowItemForm = true;
      } else {
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
    },
    closeItemForm() {
      this.$nextTick(() => {
        this.selectedItems = [];
      });
      this.popups.isShowItemForm = false;
      this.getItems();
    },

    /**
     *  (Common)
     * open Remove Dialog
     */
    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },


    /**
     *  (Common)
     * API get data
     */
    async getItems() {
      await Store.dispatch(`${STORE}/get`, this.apiParams);
    },

    // Remove
    async removeItems() {
      const company_ids = this.selectedItems.map((items) => items.id);
      const result = await Store.dispatch(`${STORE}/delete`, { company_ids });

      if (!result.hasError) {
        // get items
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
      } else {
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
      // close dialog 
      this.closeRemoveDialog();
    },

    /**
     * convert Company Type id -> name
     */
    handleCompanyType(company_type) {
      const company = COMPANY_TYPE_ARR.find(item => item.value == company_type);
      return company?.name;
    },
    /**
     * get adrress
     * prefecture + city + address
     */
    handleAddress(item) {
      return `${item.prefecture||""} ${item.city||""} ${item.address||""}`
    }
  },
};
</script>
