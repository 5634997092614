<template>
  <div ref="dialogSelf" class="FormDialog">
    <v-card height="100%">
      <v-card-title ref="FormHeader">
        <slot name="header" />
      </v-card-title>
      <v-card-text>
        <v-container>
          <slot name="main" :params="params" />
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      params: {
        mainHeight: 10,
        headerHeight: 10,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getWindowSize();
    });
    window.addEventListener("resize", this.getWindowSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.getWindowSize);
  },
  methods: {
    getWindowSize() {
      const self = this.$refs.dialogSelf;
      const header = this.$refs.FormHeader;
      if (self) {
        //全体のサイズを取得
        // const selfRect = self.getBoundingClientRect();
        const headerRect = header.getBoundingClientRect();
        //params全体を更新
        let _params = { ...this.params };
        //ヘッダーの高さを引いてMainを算出
        _params.headerHeight = headerRect.height;
        _params.mainHeight = window.innerHeight * 0.84 - headerRect.height;
        this.params = _params;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.FormDialog {
  position: fixed;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  z-index: 2;
}

.FormMain {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
