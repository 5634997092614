// FIELD_USER_TABLE_LABELS
export const FIELD_USER_TABLE_LABELS = [
  { text: "氏名", value: "name", align: "left", width: "20%" },
  { text: "所属", value: "company_name", align: "left", width: "20%" },
  { text: "", value: "role", align: "left", width: "10%" },
  { text: "", value: "representative_flag", align: "left", width: "10%" },
  { text: "メールアドレス", value: "email", align: "left", width: "20%" },
  { text: "過去日の点検", value: "flag_check_past", align: "left", width: "15%" },
  { text: "", value: "btn_dot", align: "left", width: "5%" },
];

// FIELD_USER_SORT_ITEMS
export const FIELD_USER_SORT_ITEMS = [
  { id: "user_name_kana", name: "氏名" },
  { id: "companies.name_kana", name: "所属" },
];

/**
 * FIELD_USER_CONTRACT
 */
export const FIELD_USER_ROLE = {
  ADMINISTRATOR: { id: 1, name: "管理者" },
  GENERAL: { id: 0, name: "点検者" },
};

export const TITLE = {
  NEW: "現場紐付け",
  EDIT: "現場紐付け",
};

export const FORMS = {
  Sites: { id: 1, title: "現場" },
};

export const FIELD_USER_ROLE_ARRAY = [
  { name: "点検者", value: 0 },
  { name: "管理者", value: 1 },
];

export const FORM_SITE_TABLE_LABELS = [
  { text: "氏名", value: "name", align: "left", width: "25%" },
  { text: "所属", value: "company_name", align: "left", width: "60%" },
  { text: "", value: "role", align: "left", width: "15%" },
];

export const MESSAGE_DIALOG = {
  TITLE_REJECT: "代表者解除",
  TITLE_APPROVE: "代表者",
  TITLE_REJECT_BUTTON: "解除",
  TITLE_APPROVE_BUTTON: "確定",
  TITLE_REJECT_TEXT: "代表者設定を解除します。",
  TITLE_APPROVE_TEXT: "代表者に設定します。",
};

export const INIT_MESSAGE = {
  title: "",
  text: "",
  title_button: "",
  flag: null,
  warning: null,
};

export const FLAG_CHECK_PAST = {
  PAST: {id: 1, name: "承認済"},
  UN_PAST: {id: 0, name: ""},
}
