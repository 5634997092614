import Api, { Mock } from "../api";
import mock_machine_sites from "./mock/machine_sites.json"; //mock reponse
import mock_machine_sites_detail from "./mock/machine_sites_detail.json"; //mock reponse


const URL = `${process.env.VUE_APP_API_BASE_URL}/fields`;
const GET_LIST_URL = `${URL}/get_list_machine_fields`;
const DELETE_URL = `${URL}/delete_machine_fields`;
const GET_DETAIL_MACHINE_SITE_URL = `${URL}/get_machine_field_info`;
const CREATE_MACHINE_SITE_URL = `${URL}/add_machine_field`;
const UPDATE_MACHINE_SITE_URL = `${URL}/update_machine_field`;
const EXPORT_MACHINE_FILE_QR_CODE_URL = `${process.env.VUE_APP_API_BASE_URL}/reports/exportMachineFieldQRCode`;
const END_USE_MACHINE_FIELD_URL = `${URL}/end_use_machine_field`;
const CANCLE_USE_MACHINE_FIELD_URL = `${URL}/cancel_use_machine_field`;
const GET_LIST_MACHINE_FIELD_NAME = `${URL}/get_list_machine_field_name`;
const GET_LIST_MACHINE_INSPECTOR = `${URL}/get_list_machine_inspector`;

if (Mock) {
  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_machine_sites,
  });
  Mock.onGet(GET_DETAIL_MACHINE_SITE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_machine_sites_detail,
  });

  Mock.onPost(CREATE_MACHINE_SITE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(UPDATE_MACHINE_SITE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(DELETE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
}

export const machineSites = {
  get: (params) => {
    return Api.get(GET_LIST_URL, params);
  },
  getMachineSitesDetail: async (params) => {
    return Api.get(GET_DETAIL_MACHINE_SITE_URL,params);
  },
  add: (params) => {
    return Api.post(CREATE_MACHINE_SITE_URL, params);
  },
  update: async (params) => {
    return Api.post(UPDATE_MACHINE_SITE_URL, params);
  },
  delete: async (params) => {
    return Api.post(DELETE_URL, params);
  },
  exportMachineFieldQRCode: (params) => {
    return Api.get(EXPORT_MACHINE_FILE_QR_CODE_URL, params);
  },
  endUseMachineField: async (params) => {
    return Api.post(END_USE_MACHINE_FIELD_URL, params);
  },
  cancelUseMachineField: async (params) => {
    return Api.post(CANCLE_USE_MACHINE_FIELD_URL, params);
  },
  getListMachineFieldName: async (params) => {
    return Api.get(GET_LIST_MACHINE_FIELD_NAME, {params});
  },
  getListMachineInspector: async (params) => {
    return Api.get(GET_LIST_MACHINE_INSPECTOR, {params});
  }
};
