<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <Label label="姓" :editable="editable" required>
                            <InputText
                              name="name_sei"
                              :autofocus="autofocus"
                              :values="formValues"
                              :editable="editable"
                              placeholder="山田"
                              validation_label="姓"
                              validation_rules="required|max:64"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <Label label="名" :editable="editable" required>
                            <InputText
                              name="name_mei"
                              :values="formValues"
                              :editable="editable"
                              placeholder="太郎"
                              validation_label="名"
                              validation_rules="required|max:64"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <Label label="セイ" :editable="editable" required>
                            <InputText
                              name="kana_sei"
                              :values="formValues"
                              :editable="editable"
                              placeholder="ヤマダ"
                              validation_label="セイ"
                              validation_rules="required|max:128|kana"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <Label label="メイ" :editable="editable" required>
                            <InputText
                              name="kana_mei"
                              :values="formValues"
                              :editable="editable"
                              placeholder="タロウ"
                              validation_label="メイ"
                              validation_rules="required|max:128|kana"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="所属会社" :editable="editable" required>
                        <Select
                          pinId="selectUserFormPage1"
                          name="company_id"
                          :values="formValues"
                          :items="MASTER_COMPANY_ARRAY"
                          :editable="!disableSelectCompany && !isAdminFlag"
                          validation_label="所属会社"
                          validation_rules="required"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <Label label="管理者フラグ" :editable="editable">
                      <SwitchInput
                        name="role"
                        label="管理者"
                        :values="formValues"
                        :editable="editable && !isAdminFlag"
                        @onInput="onInput"
                      />
                    </Label>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="メールアドレス" :editable="editable" required>
                        <InputText
                          name="email"
                          :values="formValues"
                          :editable="editable && !isAdminFlag"
                          placeholder="example@example.com"
                          validation_label="メールアドレス"
                          :validation_rules="getEmailRule"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <Label label="郵便番号" :editable="editable">
                            <InputText
                              name="postal_code"
                              :values="formValues"
                              :editable="editable"
                              placeholder="001-0010"
                              validation_label="郵便番号"
                              validation_rules="max:8|postcode"
                              @onInput="onChangePostalcode"
                            />
                          </Label>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <Label label="都道府県" :editable="editable">
                            <Select
                              pinId="selectUserFormPage2"
                              name="prefecture_id"
                              :values="formValues"
                              :items="STATE"
                              item_text="prefecture"
                              :editable="editable"
                              validation_label="都道府県"
                              validation_rules="max:127"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="市区町村" :editable="editable">
                        <InputText
                          name="city"
                          :values="formValues"
                          :editable="editable"
                          placeholder="千代田区"
                          validation_label="市区町村"
                          validation_rules="max:60"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <Label
                        label="番地・建物名"
                        :editable="editable"
                        class="pt-4"
                      >
                        <InputText
                          name="address"
                          :values="formValues"
                          :editable="editable"
                          placeholder="番地・建物名"
                          validation_label="番地・建物名"
                          validation_rules="max:120"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <Label label="電話番号" :editable="editable">
                            <InputText
                              name="tel"
                              :values="formValues"
                              :editable="editable"
                              placeholder="030-1234-5678"
                              validation_label="電話番号"
                              validation_rules="phone"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                  <div v-if="isShowQA">
                    <v-list-item>
                      <Label
                        label="合言葉 ※パスワード再設定時に使用します"
                      ></Label>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <Label
                              label="質問"
                              :editable="editable"
                              required
                              class="question"
                            >
                              <InputText
                                name="question"
                                :values="formValues"
                                validation_label="質問"
                                :editable="editable"
                                validation_rules="required|max:255"
                                placeholder="質問"
                                @onInput="onInput"
                              />
                            </Label>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <Label
                              label="回答"
                              :editable="editable"
                              required
                              class="answer"
                            >
                              <InputText
                                name="answer"
                                :values="formValues"
                                :editable="editable"
                                validation_label="回答"
                                validation_rules="required|max:255"
                                placeholder="回答"
                                @onInput="onInput"
                              />
                            </Label>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "../elements/Label";
import InputText from "../elements/InputText";
import Select from "../elements/Select.vue";
import SwitchInput from "@/components/forms/elements/SwitchInput";
import { Store } from "@/store/Store.js";
import { postalCodeSearch } from "@/utils/postalCodeSearch";
import { COMPANY_TYPE } from "@/constants/COMMON";

export default {
  data: () => {
    return {
      COMPANY_TYPE,
      formValues: {},
      MASTER_COMPANY_ARRAY: [],
      STATE: [{id: null, prefecture: "選択してください"},...Store.state.CmnMst.constants.entries],
      autofocus: false,
    };
  },
  components: {
    Label,
    InputText,
    Select,
    SwitchInput,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    isNewItem: Boolean,
  },
  mounted() {
    this.getListCompanies();

    this.$watch(
      () => this.editable,
      (newValue) => {
        this.autofocus = newValue;
        if (newValue && !this.isNewItem) {
          // auto focus on input text first of form
          const textFieldFirst = document.getElementsByClassName("v-text-field__slot")[0].querySelectorAll("input");
          textFieldFirst[0].focus();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.item,
      (newValue) => {
        let formValues = { ...newValue };
        if (this.isNewItem && this.USER_LOGIN.company_type !== this.COMPANY_TYPE.ADMIN) {
          formValues.company_id = this.USER_LOGIN.company_id;
        }
        this.formValues = formValues;
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => [Store.getters[`Company/getData`]],
      (data) => {
        this.MASTER_COMPANY_ARRAY = data[0];
      },
      {
        immidiate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },

    getEmailRule() {
      return "max:255|alphanumericHalfSize|email|required";
    },

    /**
     * Info user login
     */
    USER_LOGIN() {
      return Store.getters[`Login/getUser`];
    },

    disableSelectCompany() {
      let companyType = this.USER_LOGIN?.company_type;
      switch (companyType) {
        case this.COMPANY_TYPE.OWNER:
        case this.COMPANY_TYPE.PATNER:
        case this.COMPANY_TYPE.RENTAL:
          return true;
        case this.COMPANY_TYPE.ADMIN:
          return false;
        default:
          return false;
      }
    },

    isAdminFlag() {
      return this.formValues['admin_user_flg'] === 1;
    },
    isShowQA() {
      return this.isNewItem || this.USER_LOGIN.company_type === 9 || this.formValues.id == this.USER_LOGIN.id
    },
  },
  methods: {
    async getListCompanies() {
      await Store.dispatch("Company/get");
    },
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    /**
     * 郵便番号から都道府県、市町村名を検索
     */
    async onChangePostalcode({ value }) {
      const formValues = { ...this.formValues };
      formValues.postal_code = value;
      if (value == "") {
        formValues.prefecture_id = null;
        formValues.city = '';
      } else if (value.match(/^\d{3}-?\d{4}$/g)) {
        const result = await postalCodeSearch.search({ postal_code: value });
        if(result){
          formValues.prefecture_id = result.prefecture.id;
          formValues.city = result.city;
        }
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
.question {
  margin-top: -19px;
}
.answer {
  margin-top: -14px;
}
</style>
