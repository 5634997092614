<template>
    <div id="wrap">
        <template v-if="compRows[0].length != 0" v-for="(page, keyPage) in compRows"  >
          <div class="container-fluid page-item">
            <div class="title">
              <h2 class="wrap-text">{{ objectMapping.middleTitle }}月例検査表</h2>
            </div>
            <div class="content">
              <table class="w-100 pt-1 table-header">
                <tr>
                  <td class="w-10 text-center">
                    <p class="p-0">機  械  所</p>
                    <p class="p-0">有  者  名</p>
                  </td>
                  <td></td>
                  <td class="w-10 text-center">
                    <p class="p-0">型  式</p>
                    <p class="p-0">能  力</p>
                  </td>
                  <td></td>
                  <td class="w-10 text-center">
                    <p class="p-0">機   械</p>
                    <p class="p-0">番   号</p>
                  </td>
                  <td></td>
                </tr>
              </table>

              <ul class="titleContent">
                <li>記入要領</li>
                <li>レ：良好</li>
                <li>×：調整または要修理</li>
                <li>○：調整または補修したとき</li>
              </ul>
              <div class="table">
                <table class="w-100">
                  <thead class="text-center">
                  <tr>
                    <th style="width: 20px;">番 <br>号</th>
                    <th style="width: 20px;">項 <br>目</th>
                    <th style="width: 270px;" class="letter-spacing"> 検 査 項 目</th>
                    <th class="point">
                      <p class="point-1 letter-spacing">主眼点</p>
                      <p class="point-2 text-end letter-spacing">検査月日</p>
                    </th>
                    <th style="width: 50px;"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="(item, key) in page" >
                    <tr>
                      <td class="text-center">{{ item.inspection_details[0].stt }}</td>
                      <td v-bind:rowspan="item.inspection_details.length === 0 ? 1 : item.inspection_details.length"
                          class="text-center td-height" style="rotate:(-90deg);" >
                        <div class="text-td-height">
                          <span v-html="tranText(item.test_item)" class="br-none mr-auto"></span>
                        </div>
                      </td>
                      <template  v-if="item.inspection_details.length > 0">
                        <td class="text-td">
                          <div class="text-td">
                            <span>{{ item.inspection_details[0].inspection_item }}</span>
                          </div>
                        </td>
                        <td class="text-td">
                          <div class="text-td">
                            <span>{{ item.inspection_details[0].main_point }}</span>
                          </div>
                        </td>
                      </template>
                      <template  v-if="item.inspection_details.length <= 0">
                        <td></td>
                        <td></td>
                      </template>
                      <td class="text-center">レ</td>
                    </tr>
                    <template  v-if="item.inspection_details.length > 0">
                      <tr v-for="(val, k) in item.inspection_details" v-if="k > 0">
                        <td class="text-center">{{ val.stt }}</td>
                        <td class="text-td">
                          <div class="text-td">
                            <span>{{ val.inspection_item }}</span>
                          </div>
                        </td>
                        <td class="text-td">
                          <div class="text-td">
                            <span>{{ val.main_point }}</span>
                          </div>
                        </td>
                        <td class="text-center">レ</td>
                      </tr>
                    </template>
                  </template>
                  </tbody>
                </table>
                <table class="w-100">
                  <tr>
                    <td style="width: 80%;" class="text-center">検 査 者 印 ま た は サ イ ン </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 80%;" class="text-center"> 機械管理責任者印 またはサイン </td>
                    <td></td>
                  </tr>
                </table>
              </div>
              <div class="footer-table">
                <ul class="footer-table-ul">
                  <li>注１ 機械管理責任者は、１月以内ごとに１回月例検査を実施させる。 </li>
                  <li> 注２ 本検査表の検査事項の不足分については、補記し、不要分は抹消する。 </li>
                  <li> 注３ 補修したときは別紙に内容を記録する。 </li>
                  <li>注４ 記入済の本検査表は、機械所有者において３年間保存する。 </li>
                </ul>
              </div>
            </div>
          </div>
        </template>
        <template v-if="compRows[0].length == 0">
          <div class="container-fluid page-item">
            <div class="title">
              <h2>{{ objectMapping.middleTitle }}月例検査表</h2>
            </div>
            <div class="content">
              <table class="w-100">
                <tr>
                  <td>機  械  所</td>
                  <td></td>
                  <td>型  式</td>
                  <td></td>
                </tr>
              </table>

              <ul class="titleContent">
                <li>記入要領</li>
                <li>レ：良好</li>
                <li>×：調整または要修理</li>
                <li>○：調整または補修したとき</li>
              </ul>
              <div class="table">
                <table class="w-100">
                  <thead class="text-center">
                  <tr>
                    <th style="width: 20px;">番 <br>号</th>
                    <th style="width: 20px;">項 <br>目</th>
                    <th style="width: 270px;"> 検 査 項 目</th>
                    <th class="point">
                      <p class="point-1">主眼点</p>
                      <p class="point-2 text-end">検査月日</p>
                    </th>
                    <th style="width: 50px;"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <template>
                    <tr v-for="i in 32">
                      <td>
                        {{ i }}
                      </td>
                      <td><br></td>
                      <td><br></td>
                      <td><br></td>
                      <td class="text-center">レ</td>
                    </tr>
                  </template>
                  </tbody>
                </table>
                <table class="w-100">
                  <tr>
                    <td style="width: 80%;" class="text-center">検 査 者 印 ま た は サ イ ン </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 80%;" class="text-center"> 機械管理責任者印 またはサイン </td>
                    <td></td>
                  </tr>
                </table>
              </div>
              <div class="footer-table">
                <ul class="footer-table-ul">
                  <li>注１ 機械管理責任者は、１月以内ごとに１回月例検査を実施させる。 </li>
                  <li> 注２ 本検査表の検査事項の不足分については、補記し、不要分は抹消する。 </li>
                  <li> 注３ 補修したときは別紙に内容を記録する。 </li>
                  <li>注４ 記入済の本検査表は、機械所有者において３年間保存する。 </li>
                </ul>
              </div>
            </div>
          </div>
        </template>
    </div>
</template>

<script>
  export default {
    name: "TemplateTableTwo",
    props: {
      listMonthlyItem: {
        type: Array,
        default: []
      },
      objectMapping: {
        type: Object,
        default: null
      }
    },
    mounted() {
      this.$nextTick(()=>{
        this.resize();
        this.autoSetHeight('td-height');
        this.resizeHeight();
      })
    },
    methods: {
      tranText(str){
        if (str != null){
          let arr = str.split('');
          let textTran = '';
          arr.forEach(element => {
            textTran += element + `<br/>`;
          });
          return textTran;
        }
        return '';
      },
      getDays(year, month){
        return new Date(year, month, 0).getDate();
      },
      dataTran() {
        let index = 0;
        const grouped = this.listMonthlyItem.map((item) => {
          item['stt'] = index + 1;
          if(item['inspection_details'].length > 0){
            item['inspection_details'].map((val) => {
              val['stt'] = ++index;
              return val;
            });
            item['inspection_details'].push({
              stt: ++index,
              inspection_item: '',
              main_point: ''
            });
          }else {
            item['inspection_details'] = [{
              stt: ++index,
              inspection_item: '',
              main_point: ''
            }]
          }
          return item;
        });
        return grouped;
      },
      resize() {
        let tds = document.querySelectorAll(".text-td");
        tds.forEach((td) => {
          let span = td.querySelector('span');
          if (span.offsetWidth > td.offsetWidth){
            let currentFontSize = Math.floor(td.offsetWidth / span.innerText.length) || 1;
            span.style.fontSize = `${currentFontSize}px`;
          }
        });
      },
      resizeHeight() {
        let tds = document.querySelectorAll(".text-td-height");
        tds.forEach((td) => {
          let span = td.querySelector('span');
          if (span.offsetHeight > td.offsetHeight){
            let currentFontSize = Math.floor(td.offsetHeight / span.innerText.length) || 1;
            span.style.fontSize = `${currentFontSize}px`;
          }
        });
      },
      autoSetHeight(className){
        let elements = document.querySelectorAll(`.${className}`);
        elements.forEach(e => {
          let rowSpan = e.rowSpan  === undefined ? 1 : e.rowSpan;
          let height = `${rowSpan*20}px`;
          e.style.height = height;
          e.querySelector('div').style.height = height;
        })
      },
    },
    computed: {
        compRows() {
          let limit = 32;
          let grouped = [];
          let data = this.dataTran();
          let arrCountInspectionDetails = data.map(x => x.inspection_details.length);
          let totalRow = arrCountInspectionDetails.reduce((a, b) => a + b, 0);
          let numberOfPage = Math.floor(totalRow / limit) + 1;
          let startStt = 1;
          for (let index = 0; index < numberOfPage; index++) {
            let groupData = data.filter(x => x.inspection_details.filter(x => x.stt >= startStt && x.stt < (startStt + limit)).length > 0);
            groupData = groupData.map((item) => {
              return {
                ...item,
                inspection_details: item.inspection_details.filter(x => x.stt >= startStt && x.stt < (startStt + limit))
              };
            })

            if (index === numberOfPage-1){
              let lastCount = limit*numberOfPage - totalRow;
              let count = parseInt(totalRow);
              for (let index = 0; index < lastCount ; index++){
                groupData.push({
                  stt: '',
                  test_item_id: null,
                  test_item: null,
                  inspection_details: [{
                    stt: ++count,
                    inspection_item: '',
                    main_point: ''
                  }],
                })
              }
            }

            grouped.push(groupData);
            startStt = startStt + limit;
          }

          return grouped;
        },
        getCurrentDay(){
            var date = new Date();
            return this.getDays(date.getFullYear(), date.getMonth() + 1);
        },
        getCurrentMonth(){
          var today = new Date();
          var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
          return mm;
        },
        getCurrentYear(){
          var today = new Date();
          var yyyy = today.getFullYear();

          return yyyy;
        }
    }
  };
</script>

<style type="text/css" scoped>
    .letter-spacing{
      letter-spacing: 8px;
    }
    .table th{
      font-weight: 400;
    }
    .table tr {
      height: 30px;
    }
    .p-0{
      padding: 0px;
      margin: 0px;
    }
    .br-none br{
      height: 1px !important;
    }
    .wrap-text{
      font-weight: 400;
      white-space: nowrap;
      width: 600px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 auto;
    }
    .w-15{
      width: 15%;
    }
    .text-td {
      width: 260px;
      overflow: hidden;
    }
    .text-td span {
      white-space: nowrap;
      font-size: 12px;
    }
    .text-td-height {
      /*height: 100px;*/
      display: flex !important;
      align-items: center !important;
      overflow: hidden;
    }
    .text-td-height span{
      margin: 0 auto;
      font-size: 12px;
    }
    .page-item{
      padding-bottom:  25px !important;
      padding-top:  25px !important;
      border-bottom: 20px solid;
      border-color: rgba(111,111,111,0.2) transparent;
    }
    .page-item:last-child{
      border-bottom: none !important;
    }
    .scrollbar::-webkit-scrollbar {
        width: 6px;
        background-color: #F8F8F8;
        border-radius: 5px;
    }
    .scrollbar::-webkit-scrollbar-thumb{
        background: #C6C6C6;
        border-radius: 10px;
    }
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    .container-fluid{
        padding: 0 50px;
    }
    .container{
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .row{
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }
    .col-12{
        flex: 0 0 100%;
        max-width: 100%;
        box-sizing: border-box;
    }
    .col-6{
        flex: 0 0 50%;
        max-width: 50%;
        box-sizing: border-box;
    }
    @media (min-width: 768px){
        .row.separate::after{
            content: "";
            width: 1px;
            height: 100%;
            background-color: #000;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 0;
        }
        .col-md-6{
            flex: 0 0 50%;
            max-width: 50%;
            box-sizing: border-box;
        }
    }
    @media (min-width: 1200px){
        .col-lg-6{
            flex: 0 0 50%;
            max-width: 50%;
            box-sizing: border-box;
        }
    }
    .p-title{
        text-decoration: underline;
    }
    .p-title span{
        margin-left: 200px;
    }
    table{
        border-collapse: collapse;
    }
    table td, table th{
        border: 1px solid;
        min-width: 25px;
    }
    .spacer{
        margin-top: 20px;
    }
    .spacer-large{
        margin-top: 40px;
    }
    .ml-auto{
        margin-left: auto;
    }
    .mr--15{
        margin-right: -15px;
    }
    .ml--15{
        margin-left: -15px;
    }
    .pl-15{
        padding-left: 15px;
    }
    .pr-15{
        padding-right: 15px;
    }
    .w-100{
        width: 100%;
    }
    .w-10{
      width: 10%;
    }
    .w-60{
        width: 60%;
    }
    img{
        max-width: 100%;
    }
    ol{
        padding-left: 20px;
        font-size: 80%;
    }
    .box{
        border: 1px solid;
        width: 100%;
        height: 200px;
    }
    .table{
        width: 100%;
        overflow-x: auto;
    }
    table .cross{
        background-image: linear-gradient(to top right, transparent calc(50% - 1px), #000, transparent calc(50% + 1px));
    }
    .text-right{
        text-align: right;
    }
    .fw-bold{
        font-weight: 700;
    }
    .ws-nowrap{
        white-space: nowrap;
    }

    /* table2 */
    #wrap{
      width: 210mm;
      margin: 0 auto;
    }
    #wrap .title {
        text-align: center;
        margin-bottom: 20px;
    }
    #wrap .title h2{
      font-size: 22px;
    }
    #wrap .content {
        /* width: 1110px; */
        margin: auto;
    }
    #wrap .w-100 {
        width: 100%;
    }
    #wrap .text-end {
        text-align: end;
        margin-top: -5px;
    }
    #wrap .text-center {
        text-align: center;
    }
    #wrap .content ul {
        display: flex;
        gap: 300px;
        border: 1px solid;
    }
    #wrap .content ul li {
        list-style: none;
        position: relative;
    }
    #wrap .content ul li::after {
        content: "";
        background: black;
        width: 1px;
        height: 100%;
        position: absolute;
        right: -32px;
        transform: translateY(-50%);
        top: 50%;
    }
    #wrap .content ul li::before {
        content: "";
        background: black;
        width: 1px;
        height: 100%;
        position: absolute;
        left: -32px;
        transform: translateY(-50%);
        top: 50%;
    }
    #wrap .content ul li:nth-child(1)::before {
        opacity: 0;
    }
    #wrap .content .titleContent {
        border: none;
        gap: 50px;
        padding-left: 0px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    #wrap .content .titleContent li{
      font-weight: 800;
    }
    #wrap .content .titleContent li::after {
        display: none;
    }
    #wrap .content .titleContent li::before {
        display: none;
    }
    #warp .content table,
    th,
    td {
        border: 1px solid black;
        border-collapse: collapse;
    }
    #warp .content .point {
        position: relative;
    }
    #wrap .point {
        position: relative;
        width: 300px;
    }
    #wrap .point {
        background-image: linear-gradient(to top right, transparent calc(50% - 1px), #000, transparent calc(50% + 1px));
    }
    #wrap .point-1 {
        position: absolute;
        bottom: -15px;
    }
    #wrap .custom {
        display: block;
        width: 27px;
        text-align: center;
        margin: auto;
    }
    #wrap table {
        border-spacing: 0;
    }
    #wrap .footer-table {
        padding-top: 20px;
    }
    #wrap .footer-table-ul {
        border: none !important;
        display: block !important;
        padding: 0;
    }
    #wrap .footer-table-ul li::after {
        display: none;
    }
    #wrap .footer-table-ul li::before {
        display: none;
    }

</style>
