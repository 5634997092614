/**
 * USER INITAL
 */
const USER_INITAL_ITEM = {
  name_sei: null,
  name_mei: null,
  kana_sei: null,
  kana_mei: null,
  role: 0,
  email: null,
  company_id: null,
  postal_code: null,
  prefecture_id: null,
  city: null,
  address: null,
  tel: null,
  admin_user_flg: 0,
};

const USER_TABLE_LABELS = [
  {
    text: "所属",
    value: "company_name",
    align: "left",
    width: "20%",
  },
  {
    text: "氏名",
    value: "user_name",
    align: "left",
    width: "20%",
  },
  {
    text: "",
    value: "user_role",
    align: "left",
    width: "10%",
  },
  {
    text: "メールアドレス",
    value: "email",
    align: "left",
    width: "40%",
  },
  {
    text: "招待",
    value: "invite_flg",
    align: "left",
    width: "8%",
  },
];

const USER_SORT_ITEMS = [
  { id: "user_name", name: "氏名" },
  { id: "company_name", name: "所属" },
];

/**
 * USER_ROLE
 */
const USER_ROLE = [
  { id: 1, name: "管理者" },
  { id: 0, name: "点検者" },
];

const TITLE = {
  NEW: "ユーザー情報",
  EDIT: "ユーザー情報",
};

const FORMS = {
  UserFormPage: { id: 1, title: "基本情報" },
};

const INVITE_STATUS = {
  NO_INVITE: { value: 0, name: ""},
  INVITED: { value: 1, name: "招待済"}
};

export {
  USER_INITAL_ITEM,
  USER_TABLE_LABELS,
  USER_SORT_ITEMS,
  USER_ROLE,
  TITLE,
  FORMS,
  INVITE_STATUS,
};
