<template>
  <v-card class="dialog">
    <v-card-title>
      <div class="title">{{ title }}</div>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text class="body">
      {{ message }}様のログインパスワードを「<span class="number_pass">12345678</span>」にリセットします。
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed class="btn-close" @click="$emit('close')">
        やめる
      </v-btn>
      <v-btn depressed class="btn warning" @click.once="$emit('yes')">
        リセット
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    message: {
      type: String,
    },
    title: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  height: 250px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  text-align: left !important;
  line-height: 24px;
  letter-spacing: 0.1px !important;
  .title {
    color: #ff7b52 !important;
  }
  .text {
    width: 416px;
    height: 110px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 6px 6px 14px;
    color: rgba(0, 0, 0, 0.87);
  }
  .body {
    padding: 22px 22px 46px 32px;
    color: black !important;
    .number_pass {
      font-weight: 700;
    }
  }
  ::v-deep .v-card-actions {
    padding: 18px;
  }
  .btn-close {
    background: rgba(0, 0, 0, 0.38) !important;
    color: aliceblue;
  }
}
</style>
