<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :name="validation_label"
    :rules="validation_rules"
  >
    <v-textarea
      v-model="val"
      :flat="forResetEmail"
      :solo="forResetEmail"
      dense
      color="primary"
      :disabled="!editable"
      :filled="editable"
      :error-messages="errors"
      :success="valid"
      :placeholder="placeholder"
      @input="$emit('onInput', { name, value: val })"
      :no-resize="isResize"
    ></v-textarea>
  </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
export default {
  components: {
    ValidationProvider,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    validation_rules: {
      type: String,
    },
    validation_label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    isForResetEmail: {
      type: Boolean,
      default: false,
    },
    isResize: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      val: "",
    };
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        const formValues = newValue[0];
        const name = newValue[1];
        if (formValues && name) this.val = formValues[name];
      },
      { immediate: true, deep: true }
    );
  },
  computed: {
    forResetEmail() {
      return this.isForResetEmail == true ? true : false
    }
  },
};
</script>
