<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row no-gutters>
          <v-col>
            <v-row no-gutters class="custom_quantity" v-if="isNewItem ? isShowInput : typeValue == 2">
              <v-col>
                <v-list-item>
                  <v-list-item-content>
                    <Label label="連番名称" :editable="editable" required>
                      <SelectWithType
                        name="machine_field_name_prefix"
                        :values="item"
                        :items="MEDIUM_ITEM"
                        :editable="!isNewItem ? false : editable"
                        item_text="machine_field_name_prefix"
                        item_value="machine_field_name_prefix_value"
                        placeholder="テキストを入力して検索"
                        validation_label="連番名称"
                        validation_rules="required"
                        @onInput="onInput({ value: $event, index })"
                        pinId="machineFieldsInput1"
                      />
                    </Label>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <span v-if="serialNumberIsset">＋</span>
              <v-col>
                <v-list-item>
                  <v-list-item-content>
                    <Label :label="serialNumberIsset ? '新規台数' : '管理数量'" :editable="editable" required>
                      <InputText
                        name="quantity"
                        :values="isNewItem ? item : dataQuantity"
                        :editable="!isNewItem ? false : editable"
                        :validation_label="serialNumberIsset ? '新規台数' : '管理数量'"
                        :validation_rules="getRuleQuantity()"
                        @onInput="onInput({ value: $event, index })"
                      />
                    </Label>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col v-if="!isNewItem">
                <v-list-item :class="editable ? 'pt-2' : null">
                  <v-list-item-content>
                    <Label label="持込機械受理番号" :editable="editable">
                      <InputText
                        name="acceptant_number"
                        :values="formValues"
                        :editable="false"
                        @onInput="onInput({ value: $event, index })"
                      />
                    </Label>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col>
                <v-list-item>
                  <v-list-item-content>
                    <Label
                      label="機番（機械所有者管理番号）"
                      :editable="editable"
                    >
                      <InputText
                        name="machine_number"
                        :values="item"
                        :disabled="editable"
                        :editable="editable"
                        validation_label="機番（機械所有者管理番号）"
                        validation_rules=""
                        @onInput="onInput({ value: $event, index })"
                      />
                    </Label>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="customAdministrative">
                <v-list-item>
                  <v-list-item-content>
                    <div class="labelCustom">
                      <Label label="管理名（呼名）" :editable="editable"></Label>
                    </div>
                    <div :class="isShowInput && isHiddenInputTextCustom ? 'inputCustom' : ''">
                      <InputText
                        v-if="!isNewItem"
                        name="machine_field_name"
                        :values="item"
                        :editable="typeValue === 2 ? false : editable"
                        @onInput="onInput({ value: $event, index })"
                      />
                      <InputText
                        v-if="(!isHiddenInputTextCustom || !isShowInput) & isNewItem"
                        name="machine_field_name"
                        :values="item"
                        :isDisabled= "isDisabled"
                        :editable="isDisabled"
                        validation_label="管理名（呼名）"
                        validation_rules=""
                        @onInput="onInput({ value: $event, index })"
                      />
                      <v-tooltip :class="displayTooltip == true ? 'min_quantity' : 'noneToolTip'" attach>
                        <template v-slot:activator='{ on }'>
                          <InputTextCustom
                            v-if="isHiddenInputTextCustom & !isDisabled"
                            name="min_quantity"
                            :values="item"
                            :isDisabled= "isDisabled"
                            :valueQuantity="minQuantity"
                            :editable="editable"
                            validation_label="管理名（呼名）"
                            validation_rules=""
                            @mouseenter.native='on.mouseenter'
                            @mouseleave.native='on.mouseleave'
                            
                          />
                        </template>
                        <span>{{ dataToolTipMin }}</span>
                      </v-tooltip>
                      <span v-if="isShowInputText && isShowInput" class="tilde">〜</span>
                      <v-tooltip :class="displayTooltip == true ? 'max_quantity' : 'noneToolTip'" attach>
                        <template v-slot:activator='{ on }'>
                          <InputTextCustom
                            v-if="isHiddenInputTextCustom & !isDisabled"
                            name="max_quantity"
                            :values="item"
                            :isDisabled="isDisabled"
                            :valueQuantity="maxQuantity"
                            :editable="editable"
                            validation_label="管理名（呼名）"
                            validation_rules=""
                            @mouseenter.native='on.mouseenter'
                            @mouseleave.native='on.mouseleave'
                          />
                        </template>
                        <span>{{ dataToolTipMax }}</span>
                      </v-tooltip>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col :class="!isNewItem ? 'customStyleEdit' : ''">
                <v-list-item>
                  <v-list-item-content>
                    <Label label="Serial Number" :editable="editable" >
                      <InputText
                        name="serial_number"
                        :values="item"
                        :editable="!isDisabled ? !editable : editable"
                        validation_label="Serial Number"
                        validation_rules=""
                        @onInput="onInput({ value: $event, index })"
                      />
                    </Label>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <Label label="点検会社"/>
            <v-row no-gutters>
              <v-col cols="6">
                <v-list-item>
                  <v-list-item-content>
                  <Label label="会社" :editable="editable" required class="company-inspections">
                    <SelectWithFilter
                      pinId="selectFilterMachineFieldsInput1"
                      name="company_inspector"
                      :values="item"
                      :items="COMPANY_INSPECTOR"
                      :editable="editable"
                      validation_label="会社"
                      validation_rules="required"
                      @onInput="onInput({ value: $event, index })"
                    />
                  </Label>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="6">
                <v-list-item>
                  <v-list-item-content>
                    <Label label="担当者">
                      <SelectWithFilter
                        :filter-custom = "true"
                        pinId="selectFilterMachineFieldsInput2"
                        name="inspector"
                        :values="item"
                        :items="INSPECTOR_ITEM[index]"
                        :editable="editable"
                        validation_label="担当者"
                        validation_rules=""
                        @onInput="onInput({ value: $event, index })"
                      />
                    </Label>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-list-item>
              <v-list-item-content>
                <Label label="備考" class="labelRemarks">
                  <InputTextArea
                    name="remarks"
                    :values="item"
                    :editable="editable"
                    validation_label="備考"
                    validation_rules=""
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col
            v-if="index > 0 && editable"
            cols="12"
            sm="1"
            md="1"
            class="pt-0 pb-0"
          >
            <v-btn class="mt-8" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
  </div>
</template>
<script>

import Label from "@/components/forms/elements/Label.vue";
import Select from "@/components/forms/elements/Select.vue";
import InputTextArea from "@/components/forms/elements/InputTextArea.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import InputTextCustom from "@/components/forms/elements/InputTextCustom.vue";
import { Store } from "@/store/Store.js";
import SelectWithFilter from "@/components/forms/elements/SelectWithFilter.vue";
import SelectWithType from "@/components/forms/elements/SelectWithType.vue";

const INITIAL_VALUES = {
  machine_number: null,
  machine_field_name: null,
  inspector: null,
  company_inspector: null,
  serial_number: null,
  remarks: null,
  quantity: null,
};

export default {
  data: () => {
    return {
      items: [],
      INSPECTOR_ITEM : [],
      COMPANY_INSPECTOR: [],
      MEDIUM_ITEM: [],
      formValues: {
        acceptant_number: "",
      },
      dataQuantity: {
        quantity: '1',
      },
      valueQuantity: null,
      minQuantity: null,
      maxQuantity: null,
      isHiddenInputTextCustom: false,
      isShowInputText: false,
      serialNumberIsset: false,
      serialNumberData: null,
      newDataSerialNumber: null,
      typeValue: null,
      dataToolTipMax: null,
      dataToolTipMin: null,
      displayTooltip: false
    };
  },
  components: {
    Label,
    Select,
    InputTextArea,
    InputText,
    InputTextCustom,
    SelectWithFilter,
    SelectWithType,
},
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    management: {
      type: Number,
    },
    isNewItem: {
      type: Boolean,
    },
    acceptant_number: String,
    isShowInput: {
      type: Boolean,
    },
    isDisabled: {
      type: Boolean,
    },
  },

  computed: {
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    LIST_USER_BY_FIELDS() {
      return Store.getters["FieldUser/getData"];
    }
  },

  mounted() {
    this.getListCompanyByField();
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => this.values.machine_fields,
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.items = [...newValue];
          if (this.items?.length == 0) {
            this.addEmpty();
          }
          if(!this.editable && this.items?.length > 0) {
            this.getDataSelect(this.items);
          }
        }
      },
      { 
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["Sites/getListCompanyByField"],
      (data) => {
        this.COMPANY_INSPECTOR = [...data];
        this.COMPANY_INSPECTOR.unshift({
          id: null,
          name: "",
        });
      },
      { 
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => this.acceptant_number,
      (data) => {
        if(!this.isNewItem) {
          this.formValues['acceptant_number'] = data;
        }
      },
      { 
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => [Store.getters[`MachineSites/getMachineSitesDetail`], this.editable],
      (data) => {
        if(data[0].type == 2) {
          this.typeValue = data[0].type
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.getListMachineFieldName()
    this.$watch(
      () => this.isDisabled,
      (data) => {
        if(!data) {
          this.items[0].serial_number = null
          this.items[0].machine_field_name = null
          this.isHiddenInputTextCustom = false;
          this.isShowInputText = false;
          this.serialNumberIsset = false
        } else {
          if(this.items[0]) {
            this.serialNumberIsset = true
            this.items[0].quantity = null
            this.items[0].machine_field_name_prefix = null
            this.items[0].machine_field_name = null
            this.items[0].machine_field_name_range = null
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    onInput({ index, value }) {
      let _items = [...this.items];
      if(value.name == "company_inspector") {
        _items[index]["inspector"] = null;
        if(value.value) {
          this.getListInspectorItem(value.value, index);
        }else{
          const arr = [...this.INSPECTOR_ITEM];
          arr[index] = [];
          arr[index].unshift({
            id: null,
            name: "",
          });
          this.INSPECTOR_ITEM = arr;
        }
      }
      if(value.name == "machine_field_name_prefix") {
        const newArray = this.MEDIUM_ITEM.filter((item) => item.machine_field_name_prefix === (value.value.machine_field_name_prefix || value.value));
        const firstItem = newArray[0];
        this.serialNumberIsset = newArray.some(data => data.machine_field_name_prefix != null) && newArray.length > 0;
        if (this.serialNumberIsset) {
          this.serialNumberData = firstItem;
        } else {
          this.newDataSerialNumber = value.value;
        }
        this.items[index].quantity = null
        this.items[index].machine_field_name = null
        this.isHiddenInputTextCustom = false;
        this.isShowInputText = false;
      }
      const machineFieldNamePrefix = this.serialNumberIsset ? this.serialNumberData.machine_field_name_prefix : this.newDataSerialNumber;
      const machineFieldNameRange  = this.serialNumberIsset ? Number(this.serialNumberData.machine_field_name_range) : 0;
      const minQuantity = this.serialNumberIsset ? (Number(machineFieldNameRange) + 1) : 1
      const maxQuantity = this.serialNumberIsset ? machineFieldNameRange + Number(value.value) : Number(value.value)
      if(machineFieldNamePrefix && machineFieldNamePrefix.length > 10) {
        this.displayTooltip = true
        this.dataToolTipMin = `${machineFieldNamePrefix}-${minQuantity}`
        this.dataToolTipMax = `${machineFieldNamePrefix}-${maxQuantity}`
      } else {
        this.displayTooltip = false
        this.dataToolTipMin = null
        this.dataToolTipMax = null
      }
      if(value.name == "quantity") {
        if(value.value >= 1 && value.value < 101 && machineFieldNamePrefix) {
          this.minQuantity = `${this.truncateMachineFieldName(machineFieldNamePrefix, 10)}-${minQuantity}`;
          this.maxQuantity = `${this.truncateMachineFieldName(machineFieldNamePrefix, 10)}-${maxQuantity}`;
          this.isHiddenInputTextCustom = true;
          this.isShowInputText = true;
        }else {
          this.items[index].machine_field_name = null
          this.isHiddenInputTextCustom = false;
          this.isShowInputText = false;
        }
        if(!this.isDisabled && this.isHiddenInputTextCustom) {
          _items[index]['machine_field_name_range'] = machineFieldNameRange
          _items[index]['machine_field_name'] = this.minQuantity + " 〜 " + this.maxQuantity;
        }else {
          _items[index]['machine_field_name_range'] = null
        }
      }
      _items[index][value.name] = value.value;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    truncateMachineFieldName(str, size) {
      return str.length > size ? `${str.slice(0, size - 1)}…` : str;
    },
    changLabelValidator(str, strOrigin, strReplace) {
      let newMessage = str.replace(strOrigin, strReplace)
      return newMessage
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      const items = [...this.items];
      items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: items });
    },
    getDataSelect(items) {
      items.forEach((item, index) => {
        if(item.company_inspector) {
          this.getListInspectorItem(item.company_inspector, index);
        }
      });
    },
    async getListInspectorItem(company_inspector, index){
      let apiParams = {
        field_id: this.CURRENT_SITE.field_id,
        company_id: company_inspector
      }
      const response = await Store.dispatch(`FieldUser/get`,apiParams);
      if(!response.hasError) {
        let rs = [...this.LIST_USER_BY_FIELDS];
        rs.forEach((e)=>{
          e.name = e.name_sei + " " + e.name_mei;
        });
        const arr = [...this.INSPECTOR_ITEM];
        arr[index] = [...rs];
        arr[index].unshift({
          id: null,
          name: "",
        });
        this.INSPECTOR_ITEM = arr;
      }
    },
    async getListCompanyByField(){
      let apiParams = {
        field_id: this.CURRENT_SITE.field_id,
      }
      await Store.dispatch(`Sites/getCompanyByField`,apiParams);
    },
    async getListMachineFieldName() {
      let selectedTypes = [2];
      let apiParams = {
        field_id: this.CURRENT_SITE.field_id,
        type: selectedTypes
      }
      const response = await Store.dispatch(`MachineSites/getListMachineFieldName`, apiParams);
      if(!response.hasError) {
        const {entries} = response.data.contents;
        if (entries) {
          this.MEDIUM_ITEM = entries;
        }
      }
    },
    getRuleQuantity() {
      let rule = "";
      const label = this.serialNumberIsset ? '新規台数' : '管理数量';
      rule = `required-quantity:${label}`;
      return (
        rule + "|digit|minmax-pulldown:1,100"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.labelRemarks {
  margin-top: -20px;
}

.customAdministrative {
  .labelCustom {
    .labelWrapper {
      padding: 1px; 
    }
  }
  .inputCustom {
    display: flex;
    .v-input__control {
      .v-input__slot {
        background: none!important;
        .v-text-field__slot {
          input {
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
    .tilde {
      height: 40px;
      line-height: 40px;
      padding: 0 10px;
      color: rgba(0, 0, 0, 0.6);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
  }
}
.customStyleEdit {
   .labelWrapper {
    margin-top: -5px;
  }
}
.custom_quantity {
  align-items: center;
}
.max_quantity {
  ::v-deep .v-tooltip__content {
    top: unset !important;
    max-width: 200px;
    left: unset !important;
    margin-top: 40px;
    padding: 5px!important;
  }
  ::v-deep .v-text-field input {
    width: 140px;
  }
}
.noneToolTip {
  ::v-deep .v-tooltip__content {
    display: none!important;
  }
}
.min_quantity {
  ::v-deep .v-tooltip__content {
    top: unset !important;
    margin-top: 40px;
    max-width: 200px;
    padding: 5px!important;
    left: unset !important;
  }
  ::v-deep .v-text-field input {
    width: 140px;
  }
}
.company-inspections {
  ::v-deep .v-menu__content {
    max-height: 221px !important;
  }
}
</style>