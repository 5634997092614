const MACHINE_SITE_INITIAL_ITEM =
{
  type: null,
  machine_id: null,
  major_classification_id: null,
  middle_classification_id: null,
  company_id: null,
  machine_fields: [],
  machine_field_inspections: [],
  quantity: null
};

const INSPECTION_FREQUENCY_TABLE_LABELS = [
  {
    text: "点検事項",
    value: "inspection_items",
    align: "left",
    sortable: false,
    width: "35%"
  },
  {
    text: "主眼点",
    value: "main_point",
    align: "left",
    sortable: false,
    width: "65%"
  }
]
const MACHINE_SITE_TABLE_LABELS = [
  {
    text: "機械名",
    value: "middle_classification",
    align: "left",
    sortable: false,
    width: "15%"
  },
  {
    text: "仕様・能力",
    value: "machine_name",
    align: "left",
    sortable: false,
    width: "17%"
  },
  {
    text: "管理名（呼名）",
    value: "machine_field_name",
    align: "left",
    sortable: false,
    width: "15%"
  },
  {
    text: "所有会社",
    value: "company_name",
    align: "left",
    sortable: false,
    width: "13%"
  },
  {
    text: "点検会社",
    value: "company_inspector_name",
    align: "left",
    sortable: false,
    width: "15%"
  },
  {
    text: "担当者",
    value: "inspector_name",
    align: "left",
    sortable: false,
    width: "13%"
  },
  {
    text: "備考",
    value: "remarks",
    align: "left",
    sortable: false,
    width: "10%"
  },
];

const MACHINE_FIELDS_TYPE_STATUS = [
  { id: 0, name: "個体管理", value: 0 },
  { id: 1, name: "数量管理", value: 1 },
  { id: 2, name: "連番管理", value: 2 },
];
const MACHINE_SITE_SORT_ITEMS = [
  { id: "cmn_mst_machine_inspection_lists.middle_classification", name: "機械名" },
  { id: "machines.name", name: "仕様・能力" },
  { id: "companies.name", name: "所有会社" },
];
const MACHINE_SITE_TITLE = "機械一覧";
const MACHINE_SITES_FORMS = {
  MachineSitesFormPage1: { id: 1, title: "基本情報" },
  MachineSitesFormPage2: { id: 1, title: "作業前点検項目" },
  MachineSitesFormPage3: { id: 1, title: "月例点検項目" },
};
const MACHINE_SITES_TITLE_FORM = {
  NEW: "機械情報",
  EDIT: "機械情報",
};

const INSPECTION_FREQUENCY = {
  DAY: 0,
  MONTH: 1
}


const MACHINE_SITE_CATEGORY = {
  TYPE: "type",
  MACHINE_FIELDS: "machine_fields",
  QUANTITY: "quantity",
  MAJOR_CLASSIFICATION_ID: "major_classification_id",
  MIDDLE_CLASSIFICATION_ID: "middle_classification_id",
  MACHINE_ID: "machine_id",
  COMPANY_ID: "company_id",
}
export {
  MACHINE_SITE_INITIAL_ITEM,
  MACHINE_SITE_TABLE_LABELS,
  MACHINE_SITE_SORT_ITEMS,
  MACHINE_SITE_TITLE,
  MACHINE_SITES_FORMS,
  MACHINE_SITES_TITLE_FORM,
  INSPECTION_FREQUENCY_TABLE_LABELS,
  MACHINE_FIELDS_TYPE_STATUS,
  INSPECTION_FREQUENCY,
  MACHINE_SITE_CATEGORY
};
