// タブメニュー項目 自社ポータル
const HEADER_MENU_ITEMS_INHOUSE = {
  SITES: { id: 0, name: "現場", path: "../master/sites" },
  COMPANY: { id: 1, name: "会社", path: "../master/company" },
  USERS: { id: 2, name: "ユーザー", path: "../master/users" },
  MACHINES: { id: 3, name: "機械", path: "../master/machines" },
};

const HEADER_MENU_ITEMS_INHOUSE_ARRAY = Object.keys(
  HEADER_MENU_ITEMS_INHOUSE
).map((key) => {
  return HEADER_MENU_ITEMS_INHOUSE[key];
});

// タブメニュー項目 自社ポータル
const HEADER_MENU_ITEMS_FIELD = {
  USER_FIELDS: { id: 0, name: "ユーザー", path: "../field/users" },
  MACHINE_FIELDS: { id: 1, name: "機械", path: "../field/machines" },
  INSPECTOR_FIELD: { id: 2, name: "実績", path: "../field/inspections" },
};

const HEADER_MENU_ITEMS_FIELD_ARRAY = Object.keys(
  HEADER_MENU_ITEMS_FIELD
).map((key) => {
  return HEADER_MENU_ITEMS_FIELD[key];
});

// タブメニュー項目 自社ポータル
const MENU_DRAWER = {
  PORTAL: { id: 0, name: "大分類", path: "" },
  SITES: { id: 1, name: "中分類", path: "" },
};

const MENU_DRAWER_ARRAY = Object.keys(
  MENU_DRAWER
).map((key) => {
  return MENU_DRAWER[key];
});

const STORAGE_KEY = "GRN_HEADERS";

export {
  HEADER_MENU_ITEMS_INHOUSE,
  HEADER_MENU_ITEMS_INHOUSE_ARRAY,
  HEADER_MENU_ITEMS_FIELD,
  HEADER_MENU_ITEMS_FIELD_ARRAY,
  MENU_DRAWER_ARRAY,
  MENU_DRAWER,
  STORAGE_KEY,
};
